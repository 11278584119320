/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

/* New Dashboard Style 2.0 */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@300;400;500;600;700;800&display=swap');

::selection,
::-moz-selection {
    background-color: #1088ff;
    color: #fff;
}

a:hover,
a:focus {
    color: #1088ff;
}


/* common styles */

a {
    color: #1088ff;
}

.btn {
    font-weight: 600;
}

.mb-10 {
    margin-bottom: 10px;
}

.holidays-history-table .dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu,
.dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu {
    top: auto;
    bottom: 40px;
}

.holidays-history-table .dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu::before,
.holidays-history-table .dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu::after,
.dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu::before,
.dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu::after {
    bottom: -10px;
    top: auto;
    border-bottom: none;
    border-top: 11px solid #FFFFFF;
    display: none;
}

.holidays-history-table .dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu::before,
.dropdown.react-bs-table-sizePerPage-dropdown .dropdown-menu::before {
    bottom: -11px;
    border-bottom: 11px solid rgba(0, 0, 0, 0.2);
}

.btn-primary.btn-fill,
.btn-primary {
    background-color: #1088ff;
    color: #fff;
}

.dropdown-with-icons .dropdown-menu i[class^="fa"] {
    font-size: 18px;
}

.card .content .table-responsive.table_doctor_login {
    padding: 0;
}

.bg-thumbcolor,
.btn-primary.btn-fill {
    /* background-color: #1088ff; */
}

.bg-thumbcolor button.close {
    /* color: #000; */
}

.bg-thumbcolor h4 {
    /* color: #000; */
}

.modal-content {
    border-radius: 10px !important;
    overflow: hidden;
    border: none;
}

.Patient-headers {
    /* background-color: #1088ff; */
    border: none;
}


/* .btn-primary.btn-fill:hover,
.btn-primary.btn-fill:focus,
.btn-primary.btn-fill:active,
.btn-primary.btn-fill.active,
.open>.btn-primary.btn-fill.dropdown-toggle {
    background-color: #23CCEF;
    color: #FFFFFF;
} */

.mt-10 {
    margin-top: 10px !important;
}


/* common style end */

body,
h1,
.h1,
h2,
.h2,
h3,
.h3,
h4,
.h4,
h5,
.h5,
h6,
.h6,
p,
.navbar,
.brand,
.btn-simple,
.alert,
a,
.td-name,
td,
button.close {
    font-family: 'Inter', sans-serif !important;
}

body {
    color: #000;
}

.dashboard-2-0 .btn {
    border-radius: 10px;
    opacity: 1;
}

.main-content.dashboard-2-0 {
    padding: 8px 0 !important;
}

.dashboard-2-0 .btn-primary.btn-fill {
    /* background-color: #1088ff; */
}

.dashboard-2-0 .welcome-user {
    display: flex;
    align-items: center;
    width: 100%;
    margin: 0;
    margin-bottom: 5px;
}

.welcome-user .flex-col {
    flex: 1;
}

.welcome-user h1 {
    font-size: 18px;
    font-weight: 600;
    margin: 0;
    /* font-family: 'Inter'; */
}

.welcome-user p {
    margin: 0;
}

.welcome-user .right-info {
    text-align: right;
}

.dashboard-grid {
    display: grid;
    grid-template-columns: 1fr 210px;
    gap: 20px;
}

.aside-cards {
    display: flex;
    width: 100%;
    gap: 20px;
    flex-direction: column;
    cursor: pointer;
}

.aside-card {
    background: #FFFFFF;
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    padding: 23px 12px;
    position: relative;
}

.aside-card h4 {
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 0;
    display: flex;
    justify-content: space-between;
}

.aside-card .card-icon {
    margin-bottom: 15px;
}

.card-badge {
    background-color: #D7D7D7;
    border-radius: 20px;
    padding: 5px;
    font-size: 12px;
    position: absolute;
    line-height: 1;
    top: 10px;
    text-transform: uppercase;
    right: 12px;
}

.front-nav ul.nav.navbar-nav.navbar-right li.active {
    border-radius: 10px;
    font-weight: 600;
    /* background: #eee;
    border: 1px solid #ccc;
    background: linear-gradient(180deg, #eee 0, #fff);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#eeeeee", endColorstr="#ffffff", GradientType=0); */
}

.front-nav ul.nav.navbar-nav.navbar-right li.active a {
    font-weight: 600;
}

.front-nav ul.nav.navbar-nav.navbar-right li {
    position: relative;
    /* border: none;
    background: unset; */
    margin: 0;
    border-radius: 10px;
}

.front-nav ul.nav.navbar-nav li a {
    font-size: 10px;
    /* text-transform: uppercase; */
    /* padding: 5px 10px !important; */
}

.front-nav ul.nav.navbar-nav li a img {
    max-width: 30px;
    max-height: 30px;
    margin-bottom: 5px;
}

.logo-h {
    max-height: 50px;
}

span.chatCount {
    top: 0px;
    right: 10px;
    /* background-color: rgb(2, 112, 221, 0.9); */
}

nav.front-head.navbar.navbar-default {
    border: none;
}

nav.front-head .navbar-nav {
    display: flex;
    align-items: center;
}

.table-card {
    background: #FFFFFF;
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    height: 100%;
}

.table-card .table-card-header {
    padding: 10px 20px 5px 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.table-card-header-action .btn {
    margin-left: 10px;
}

.table-card .table-card-header h4 {
    font-size: 16px;
    font-weight: 700;
    margin: 0 !important;
    line-height: 1;
}

.table-card .table-card-header .btn-link {
    font-size: 14px;
    font-weight: 600;
    color: #1088ff;
}

.ad-carousel {
    margin-top: 20px;
    background-color: #fff;
    border-radius: 10px;
    overflow: hidden;
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgb(0 0 0 / 25%);
}

.slider-img {
    position: relative;
    background-color: #b6b6b6;
}

.slider-img img {
    width: 100%;
    aspect-ratio: 1/1;
    object-fit: contain;
    opacity: 1 !important;
}

.ad-carousel .carousel-caption {
    position: static;
    text-align: left;
    color: #000;
    text-shadow: none;
}

.ad-carousel .carousel-inner>.item {
    background-color: #fff;
}

.Select-meun-outer {
    z-index: 9999999 !important;
}

.react-bs-table-container .react-bs-table .react-bs-container-body table tbody tr td:last-child>div.d-flex {
    padding: 0 !important;
    justify-content: flex-start;
}

.d-flex {
    display: flex;
}

.align-items-center {
    align-items: center;
}

.star {
    line-height: 1;
}

.clinic-user-password {
    display: flex;
}

.clinic-user-password>div {
    display: flex;
    flex-direction: column;
    flex: 1;
    border: 1px solid #eee;
    padding: 5px;
}

.clinic-user-password>div span {
    border-top: 1px solid #eee;
    padding-top: 5px;
    margin-top: 5px;
}

.ad-carousel .carousel-indicators {
    visibility: visible;
    bottom: auto;
    top: 180px;
    margin: 0 auto;
    transform: translateX(-50%);
}

.ad-carousel .carousel-indicators li {
    background-color: #e8e8e8;
}

.ad-carousel .carousel-indicators .active {
    background-color: #23CCEF;
}

.ad-carousel .carousel-control.left,
.ad-carousel .carousel-control.right {
    display: none;
}

.slider-content {
    padding: 10px;
}

.slider-content h4 {
    font-size: 14px;
    margin-bottom: 0 !important;
}

.slider-content h5 {
    font-size: 12px;
    font-weight: 700;
    margin-top: 0;
}

.dashboard-2-0 .dashboard-grid .dashboard-table .table,
.dashboard-2-0-table table.table {
    /* border: none; */
    min-width: auto;
}

.dashboard-2-0 .table>thead>tr>th {
    /* background-color: #FCFBFC; */
    background-color: #fff;
    color: #000;
    /* border: none !important; */
    text-transform: capitalize;
    font-size: 14px;
    font-weight: 600;
}

.dashboard-2-0 .table-bordered>tbody>tr>td,
.dashboard-2-0 .table-bordered>tbody>tr>th,
.dashboard-2-0 .table-bordered>tfoot>tr>td,
.dashboard-2-0 .table-bordered>tfoot>tr>th,
.dashboard-2-0 .table-bordered>thead>tr>td,
.dashboard-2-0 .table-bordered>thead>tr>th {
    padding: 6px 5px !important;
    /* border-left: 0 !important;
    border-right: 0 !important; */
}

.frontend .notification-front .dashboard.dashboard-2-0 .table-responsive-new .dashboard-table table.table td,
.frontend .notification-front .dashboard.dashboard-2-0 .table-responsive-new .dashboard-table table.table th {
    padding: 6px 5px !important;
}

.frontend .notification-front .dashboard.dashboard-2-0 .table-responsive-new .dashboard-table table.table td>table tr td {
    padding: 0 !important;
}

.dashboard-2-0 .dashboard-table {
    padding: 0 15px;
}

.dashboard-2-0 .pt-blue-color {
    color: #1088ff !important;
}

.chat-temp-count {
    padding-right: 10px;
    font-size: 12px;
    color: #484848;
}

.dashboard-2-0 .doc-label {
    /* font-weight: 600; */
    display: flex;
    align-items: center;
}

.dashboard-2-0 .doc-label-flex {
    justify-content: flex-start;
    align-items: center;
    gap: 10px;
}

.dashboard-2-0 .doc-label-flex img {
    width: 24px;
    max-height: 24px;
}

.dashboard-2-0 .doc-label img {
    width: 24px;
    height: 24px;
}

.dashboard-2-0 .badge {
    /* background-color: #D9D9D9 !important; */
    width: 24px;
    height: 24px;
    min-width: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    /* color: #000; */
    line-height: 1;
    font-size: 12px !important;
    font-weight: 400;
    border-radius: 100% !important;
    padding: 3px !important;
    margin: 0 !important;
    margin-right: 3px !important;
}

.dashboard-2-0 .badge img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    max-width: 100%;
}

.dashboard.dashboard-2-0 .table-bordered th,
.dashboard.dashboard-2-0 .table-bordered td {
    text-transform: capitalize;
}

.side-modal {
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    height: 100%;
    min-height: 100vh;
    min-width: 500px;
    max-width: 800px;
    display: block;
    background: #FFFFFF;
    box-shadow: 0px 1px 30px -2px rgba(0, 0, 0, 0.25);
    z-index: 99;
    transition: 0.3s all;
}

.side-modal:after {
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    background-color: #fff;
    width: 100vw;
    height: 100vh;
    opacity: 0.3;
    z-index: -1;
}

.side-modal-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 15px;
}

.side-modal-header h2 {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
}

.chat-template {
    max-height: 400px;
    overflow-y: auto;
}

.dashboard-2-0 .btn-link {
    font-weight: 700;
    color: #1088ff;
    font-size: 15px;
    padding: 0;
    border: none;
    background: none;
}

.side-modal-header-action {
    display: flex;
    align-items: center;
    gap: 30px;
}

span.close-btn {
    line-height: 1;
    cursor: pointer;
}

.side-modal-content {
    padding: 15px;
    padding-bottom: 50px;
    height: calc(100vh - 100px);
    /* overflow-x: hidden; */
    overflow-y: auto;
}

.note-modal .side-modal-content {
    max-width: 500px;
}

.content-item {
    position: relative;
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
    padding-left: 20px;
}

.content-item:after {
    content: "";
    position: absolute;
    top: 12px;
    left: 0;
    width: 7px;
    height: 7px;
    background-color: #000;
    border-radius: 50px;
}

.admin-notification-modal .content-item:after {
    display: none;
}

.admin-notification-modal .content-item {
    padding-left: 0;
}

.item-action {
    display: flex;
    gap: 20px;
}


/* Note modal */

.note-modal h4 {
    line-height: 1;
}

.note-modal .content-item:after {
    top: 4px;
}

.add-note {
    position: relative;
}

.dashboard-2-0 .btn-add-note {
    position: absolute;
    bottom: 15px;
    right: 15px;
    background-color: #D5D5D5;
    color: #000;
    padding: 10px 15px;
    width: 90px;
    font-weight: 600;
    border: none;
}

.add-note textarea {
    height: 144px;
}

.add-note .content-items-wrapper {
    border-top: 1px solid #eee;
    margin-top: 30px;
    padding-top: 30px;
}

h3 {
    font-size: 14px;
    font-weight: 700;
}

.content-item h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 0px !important;
}

.admin-notification-modal .content-item-col {
    display: flex;
    gap: 10px;
}

.notification-count {
    font-size: 12px;
    font-weight: 600;
}

.content-item span.text-mute {
    color: #767676;
    font-size: 13px;
}

.note-list .content-item h4 {
    margin-bottom: 5px !important;
}

.note-list .content-item .content-item-p {
    margin-bottom: 0;
}

.content-item h4::first-letter {
    text-transform: uppercase;
}

.content-item .content-item-p {
    margin-bottom: 5px;
    font-size: 12px;
}

.badges {
    background-color: #D7D7D7;
    color: #000;
    padding: 2px 5px;
    border-radius: 10px;
    margin-left: 10px;
}

.content-items-wrapper.note-list {
    margin-top: 25px;
    border-top: 1px solid #DFDFDF;
}

.dashboard-2-0-tabs-inner {
    border-bottom: 1px solid #dfdfdf;
    display: flex;
}

.dashboard-2-0-tabs-inner {
    color: #767676;
}

.btn-tab {
    background: unset;
    border: none;
    border-bottom: 2px solid #fff;
    padding: 10px 15px;
    color: #767676;
    font-weight: 600;
}

.btn-tab.btn-tab-active {
    color: #000;
    border-color: #1088ff;
}

.table-action-ic {
    width: 20px;
}

.dashboard-2-0 .eductor-tbl,
.dashboard-2-0 .dietTbl {
    text-align: left;
}


/* Petient Details */

.details-empty {
    display: flex;
    /* justify-content: center; */
    align-items: center;
    height: calc(100% - 60px);
    text-align: center;
}

.details-empty h4 {
    font-weight: 600;
    font-size: 16px;
    line-height: 1;
}

.details-empty p {
    font-size: 14px;
}

.patient-details {
    height: 100%;
}

#patient-details-tabs {
    width: 100%;
}

#patient-details-tabs .nav-tabs {
    padding: 0 20px;
    margin-top: 0;
}

#patient-details-tabs .nav-tabs>li>a,
#patient-details-tabs .nav-tabs>li>a:hover,
#patient-details-tabs .nav-tabs>li>a:focus {
    color: #767676;
    font-size: 14px;
    font-weight: 600;
    padding: 15px;
    border: none;
}

#patient-details-tabs .nav-tabs>li.active>a,
#patient-details-tabs .nav-tabs>li.active>a:focus,
#patient-details-tabs .nav-tabs>li.active>a:hover {
    background: unset;
    border: none;
    border-bottom: 2px solid #23CCEF;
    color: #000;
    font-weight: 700;
}

.card .content .table-responsive {
    /* padding: 0; */
}

#patient-details-tabs .tab-content {
    padding: 0 20px;
}

.patient-details .dashboard-2-0-tabs-inner {
    border: none;
}

.vital_signs_wrapper {
    background: #FFFFFF;
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    margin-bottom: 20px;
    padding: 15px 20px;
}

.vital_signs_wrapper_inner {
    display: flex;
    align-items: center;
    gap: 10px;
    width: 100%;
}

.vital-usr-img {
    width: 24px;
    height: 24px;
    background-color: #D9D9D9;
    border-radius: 100%;
    overflow: hidden;
}

.vital-usr-img img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.vital-usr-name {
    font-weight: 600;
    font-size: 16px;
}

.signs {
    display: flex;
    gap: 10px;
    font-weight: 300;
    font-size: 16px;
    align-items: center;
}

.signs>div {
    padding: 0 15px;
    position: relative;
}

.signs>div:after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 1px;
    height: 24px;
    background-color: #000;
    opacity: 0.4;
}


/* EMR styles */

.general-information .card_header {
    background: #3f80c1;
    /* border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgb(0 0 0 / 25%); */
    border-radius: 10px;
    padding: 6px 15px;
    position: relative;
    color: #000;
    margin-bottom: 20px;
    width: 100%;
}

.emr-wrapper .general-information .card_header {
    margin-bottom: 0;
}

.card_header_inner ul.patient_details {
    /* overflow: auto;
    flex-wrap: nowrap;
    white-space: nowrap; */
    margin-right: 15px;
}

.card_header_inner ul.patient_details li {
    margin-right: 10px;
    padding-right: 10px;
    border-right: 1px solid rgba(255, 255, 255, 0.36);
    font-size: 14px;
}

.card_header_inner ul.patient_details>li:first-child {
    min-width: 100px;
}

.general-information .card_header_inner ul.patient_details li a {
    color: #fff;
}

.general-information .patient_history_btn .btn-secondary {
    background-color: #BABABA;
    color: #000;
    border: none;
    font-weight: 600;
}

.breadcrum {
    margin-bottom: 0;
    display: flex;
    font-size: 12px;
}

.breadcrum span {
    padding: 0 15px;
    position: relative;
}

.breadcrum span:first-child {
    padding-left: 0;
}

.breadcrum span:after {
    content: ">";
    position: absolute;
    top: 0;
    left: 95%;
    width: 20px;
    height: 20px;
}

.breadcrum span:last-child::after {
    display: none;
}

.patient_details a.user-info-img {
    display: flex;
    gap: 10px;
    align-items: center;
    font-weight: 600;
}

.patient_details .user-info-img span {
    width: 24px;
    height: 24px;
    border-radius: 100%;
    background-color: #BABABA;
    overflow: hidden;
}

.patient_details .user-info-img span img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.pending-action {
    background: #3f80c1;
    /* border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgb(0 0 0 / 25%); */
    border-radius: 10px;
    padding: 6px 15px;
    position: relative;
    color: #fff;
    margin-bottom: 0px;
    display: flex;
    align-items: center;
    gap: 15px;
    width: 210px;
    min-width: 210px;
}

.pending-title h4 {
    font-size: 14px;
    margin: 0 !important;
    line-height: 1.2;
}

.general-information {
    display: flex;
    gap: 20px;
    /* align-items: center; */
}

.cal-img {
    max-width: 300px;
}

.priscription-snapshot {
    padding: 0 20px;
    padding-top: 20px;
    border-top: 1px solid #eee;
}

.priscription-snapshot .doctor-signs {
    margin-top: 20px;
}

.priscription-snapshot .recommendations-list {
    font-size: 14px;
}

.priscription-snapshot .bottom-recommendations-list {
    font-size: 14px;
}

.priscription-snapshot .drug-list-item {
    padding: 6px 0;
}

.priscription-snapshot .t-dose-time {
    line-height: 1.5;
}

.user-name-log {
    display: flex;
    text-align: left;
}

.user-name-log>div {
    display: flex;
    flex-direction: column;
}

.user-name-log>div span:last-child {
    color: #f44336;
    font-weight: bold;
    font-size: 12px;
}

.front-nav ul.nav.navbar-nav.navbar-right li:last-child {
    max-width: unset;
    background: unset;
    border: none;
}

.front-nav ul.nav.navbar-nav li.tran-nonee.logout-menu a {
    padding: 6px !important;
}

.front-nav ul.nav.navbar-nav li a .doctor-info:hover span:nth-child(1) {
    color: red;
}

.card-content-inner .emr-menu-list ul {
    display: flex;
    width: 100%;
}

.emr-tab-content {
    background: #FFFFFF;
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgb(0 0 0 / 25%);
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    padding: 23px 12px;
    position: relative;
}

.sytems-wrapper-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.sytems-wrapper-header h5 {
    font-size: 16px;
    font-weight: 600;
}

.systems-action {
    display: flex;
    gap: 15px;
    margin-bottom: 15px;
}

.systems-lists-wrapper {
    background-color: #ebebeb;
    padding: 15px;
    border-radius: 4px;
    position: relative;
}

.systems-lists .systems-lists-master .form-check.form-check-inline {
    display: block;
    width: 100%;
}

.systems-lists.form-group {
    display: flex;
}

.systems-lists-master {
    min-width: 150px;
}

.suggestion-box h6,
.sytems-wrapper h6 {
    font-size: 14px;
    font-weight: 600;
    text-transform: capitalize;
}

.suggestion-box {
    border-top: 1px solid #ddd;
    padding-top: 10px;
}

.suggetion-box-header {
    display: flex;
    justify-content: space-between;
}

.dashboard-table .calnder {
    min-width: 300px;
    margin-right: 10px;
}

.selected-complaints {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.emr_patient {
    flex: auto;
}

.sytems-wrapper hr {
    border-color: #ddd;
}

.dashboard-2-0 .emr-tab-content table thead th {
    font-size: 14px;
    font-weight: 600;
    color: #33363F;
}

.emr-tab-content h5 {
    font-size: 14px;
    font-weight: 600;
}

span.actn-btn a {
    padding: 0 5px;
}

.search-sec {
    padding: 10px;
    background-color: #EFEFEF;
}

.additional-details-inner {
    padding: 10px;
    background-color: #EFEFEF;
}

.additional-details h5 {
    font-size: 14px;
    font-weight: 600;
}

.additional-details h6 {
    text-transform: capitalize;
    font-size: 12px;
    font-weight: 600;
}

#search-type .form-check label {
    font-weight: 400;
}

#btnShowRecommendation {
    /* background-color: #BABABA;
    color: #000; */
    border: none;
    font-weight: 600;
    padding: 13px;
}

.additional-details .radio-wrapper {
    display: flex;
    width: 100%;
    gap: 30px;
}

.custom-calender h5 {
    font-size: 14px;
    font-weight: 600;
}

.custom-calender .calnder {
    padding: 10px;
    background: #f5f5f5;
}

.custom-calender .calnder .cs-datepick {
    margin-bottom: 15px;
}

.history-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 20px;
    margin-top: 20px;
}

.history-col {
    background: #FFFFFF;
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 0.5px 1px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 10px 12px;
    position: relative;
    width: 100%;
}

.dashboard-2-0.emr-wrapper .table>thead>tr>th {
    color: #33363F;
}

.emr-wrapper h5 {
    font-size: 14px;
    font-weight: 600;
    margin-top: 0;
}

.form-action {
    display: flex;
    gap: 20px;
    justify-content: end;
}

.form-action button {
    flex: 1;
}

iframe {
    display: block;
}

.holidays-history-table {
    overflow-x: auto;
    overflow-y: hidden;
}

.holidays-history-table .pagination {
    margin-top: 0;
}

.holidays-history-table .col-md-6,
.holidays-history-table .pagination {
    text-align: right;
}

.pagination>li>a,
.pagination>li>span {
    min-width: 34px;
}

.modal-content {
    overflow: inherit;
}

.dashboard-2-0 .clinic-calenders .btn-primary.btn-fill,
.dashboard-2-0 .clinic-calenders .btn-info.btn-fill {
    max-width: 100px;
    flex: 1 1 100px;
}

.emr-menu-list {
    margin-top: 5px;
}

.for-lab-test .calnder {
    max-width: 300px;
}

.lab-test-data label {
    font-weight: 300;
    flex: 1;
}

.lab-test-data span {
    display: flex;
}

div#Glucose_Chart_Ajax {
    overflow: auto;
}

.task-screen .card .header {
    position: relative;
}

.sidebar,
.bootstrap-navbar,
.off-canvas-sidebar .navbar-collapse {
    padding-left: 15px;
    padding-right: 15px;
}

.nav-open .nav .caret {
    border-bottom-color: #000;
    border-top-color: #000;
}

.patient-details.details-empty .details-inner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.patient-details.details-empty .details-inners {
    top: 50%;
    left: 50%;
}

.table-card {
    position: relative;
}

.frontend table th::first-letter,
.frontend table td::first-letter,
.frontend .table-responsive-new table.table-bordered th::first-letter,
.frontend .table-responsive-new table.table-bordered td::first-letter {
    text-transform: uppercase;
}

.min-hieght {
    min-height: 350px;
}

.video-document-color {
    background-color: #053C64;
}

.modal-header {
    border-top-right-radius: 10px;
    border-top-left-radius: 10px;
}

.react-bs-table-no-data {
    height: 150px;
    text-align: center;
    font-size: 16px !important;
    font-weight: 600;
}

.frontend .notification-front .dashboard.dashboard-2-0 .table-responsive-new .dashboard-table table.table th table td {
    padding-left: 0 !important;
}

li.favorite-ic {
    position: absolute;
    top: 15px;
    right: 15px;
    background: #fff;
    padding: 3px;
    width: 26px;
    height: 26px;
    border-radius: 100%;
    text-align: center;
    z-index: 2;
    box-shadow: 1px 1px 10px rgba(0, 0, 0, 0.3);
}


/* Setting page styles */

.tabs-content-inner {
    display: flex;
    gap: 30px;
}

.tabs-content {
    margin-top: 40px;
}

.tabs-btns-group {
    border-bottom: 1px solid #eee;
    display: flex;
    gap: 5px;
}

.btn-tab.tab-active {
    border-bottom: 2px solid #1088ff;
}

.tabs-inside-tabs {
    width: 250px;
}

.tabs-inside-tabs-content {
    width: calc(100% - 250px);
    border-left: 1px solid #E3E3E3;
    padding-left: 30px;
}

.tabs-inside-tabs-content h4.title {
    margin-bottom: 15px !important;
    display: flex;
    align-items: center;
    gap: 15px;
    flex-wrap: wrap;
}

#share {
    position: absolute;
    top: 0;
    left: 80px;
}

.tabs-inside-tabs-content .settings-userlist h4.title {
    margin-bottom: 0 !important;
}

.tabs-inside-tabs .btn-tab {
    width: 100%;
    border: none;
    border-bottom: 1px solid #eee;
}

.tabs-inside-tabs button.btn-tab.tab-active {
    background-color: #33363F;
    color: #fff;
    border-radius: 4px;
}

.doctor-info-inner {
    display: flex;
    gap: 40px;
}

.dr-image {
    max-width: 300px;
}

.dr-image img {
    width: 100%;
}

.notation-header.notations-title {
    background-color: #f5f5f5;
    padding: 5px 10px;
    font-size: 16px;
    margin-bottom: 15px;
}

.notation-header.notations-title {
    background-color: #cdcdcd;
    padding: 5px 10px;
    color: #000;
}

.notation-list>p {
    display: flex;
    align-items: center;
    font-size: 14px;
}

span.notation {
    display: inline-block;
    width: 20px;
    height: 20px;
    min-width: 20px;
    margin-right: 10px;
    font-size: 10px;
    color: #fff;
    text-align: center;
    vertical-align: middle;
    line-height: 20px;
    font-weight: 600;
}

span.notation.mobile-app {
    line-height: 1;
}

.notation-red {
    background-color: red;
}

.notation-green {
    background-color: green;
}

.notation-blue {
    background-color: #1088ff;
}

.notation-cream {
    background-color: lightsalmon;
}

.notation-black {
    background-color: black;
}

.notation-yellow {
    background-color: yellow;
}

.notation-pink {
    background-color: palevioletred;
}

.circle {
    border-radius: 50px;
}

span.notation img {
    width: 100%;
    height: 100%;
}

.upload-document-wrapper .card .header {
    padding: 0;
}

.dr-information .lable {
    color: #767676;
}

.dr-information p {
    font-size: 14px;
}

.settings-wrapper .btn-tab {
    background-color: #fafafa;
}

.clinic-password-management-tab .table-responsive {
    overflow: auto;
}

.aside-cards-style {
    background: #FFFFFF;
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 1px 3px rgb(0 0 0 / 25%);
    border-radius: 10px;
    display: flex;
    align-items: center;
    gap: 10px;
    color: #000 !important;
    padding: 10px;
    min-width: 100px;
}

.aside-cards-style img {
    max-width: 28px;
    max-height: 28px;
    width: 100%;
}

.knowledge-share-cards {
    display: flex;
    column-gap: 50px;
    row-gap: 30px;
    margin-bottom: 20px;
    flex-wrap: wrap;
    margin-top: 20px;
    justify-content: center;
}

.knowledge-share-modal .knowledge-share {
    padding-left: 0;
}

.knowledge-share .chart_flexs a.knowledge-share-card {
    /* background: #FFFFFF; */
    border: 0.5px solid #FCFBFC;
    box-shadow: 0px 2px 5px rgb(0 0 0 / 25%);
    border-radius: 10px;
    padding: 23px 12px;
    position: relative;
    color: #fff !important;
    height: 150px;
    display: flex;
    flex-direction: column;
    gap: 15px;
    justify-content: center;
    text-align: left;
    font-size: 22px;
    font-weight: 600;
    width: calc(33.33% - 30px);
    max-width: 320px;
    flex: 1 auto;
    margin: 0 !important;
}

.knowledge-share-modal .historybtn .btn-primary.btn-fill {
    /* width: 100%;
    margin-top: 3px; */
}

ul.taskscreen_aditional-comments {
    display: flex;
    flex-wrap: wrap;
    gap: 10px;
}

ul.taskscreen_aditional-comments li {
    width: calc(33.33% - 10px);
    font-size: 15px;
}

ul.taskscreen_aditional-comments li .checkbox {
    margin: 0;
}

ul.taskscreen_aditional-comments li .relat {
    display: flex;
    align-items: baseline;
    gap: 10px;
}

ul.taskscreen_aditional-comments li .relat .pdf-ri {
    position: static;
    padding: 1px;
    font-size: 12px;
    text-align: center;
}

.knowledge-share-card .knowledge-share-card-title {
    position: relative;
}

.knowledge-share-card .knowledge-share-card-title::after {
    content: "";
    background-image: url(../img/icons/card-icon-white.svg);
    position: absolute;
    top: 5px;
    right: 0;
    background-size: 10px;
    width: 20px;
    height: 20px;
    background-repeat: no-repeat;
    background-position: right;
}

.knowledge-share-modal.pa-task-screen .spc-card legend {
    text-align: center;
    font-size: 28px;
}

.knowledge-share-modal.pa-task-screen .modal-lg.modal-dialog {
    max-width: 1200px;
}

.educator-header .knowledge-share .chart_flexs a.knowledge-share-card {
    height: auto;
    grid-gap: 10px;
    padding: 10px;
    font-size: 14px;
}

.educator-header .knowledge-share .chart_flexs a.knowledge-share-card img {
    max-width: 20px !important;
}

.educator-header .knowledge-share-card .knowledge-share-card-title::after {
    top: 0px;
    background-size: 8px;
}

.educator-header .knowledge-share-cards {
    gap: 10px;
}

.educator-end .knowledge-share-cards {
    column-gap: 10px;
    row-gap: 10px;
}

.educator-end .knowledge-share-cards {
    column-gap: 10px;
    row-gap: 10px;
}

.educator-end .knowledge-share .chart_flexs a.knowledge-share-card {
    height: auto;
    padding: 10px;
    font-size: 18px;
    width: calc(20% - 30px);
}

.educator-end .knowledge-share .chart_flexs a.knowledge-share-card img {
    max-width: 20px;
}

.sidebar .nav li>a {
    margin: 0;
}

li.activeDropDownClass {
    background: rgba(0, 0, 0, 0.5);
    border-radius: 4px;
}

.sidebar .nav li:hover>a {
    background-color: unset;
    /* color: #3eaaea; */
}

.sidebar .sidebar-wrapper {
    width: 270px;
    height: calc(100% - 95px);
    padding-bottom: 0;
    box-shadow: none;
}

.sidebar .logo {
    box-shadow: none;
}

.sidebar .nav li.active>a {
    color: #3eaaea;
    background: unset;
}

.sidebar .nav li.active>a p {
    color: #3eaaea;
}

.sidebar .nav i {
    /* font-size: 22px;
    margin-right: 10px;
    width: 24px; */
}

.sidebar .sidebar-wrapper .nav ul>li>a .sidebar-mini,
.sidebar .sidebar-wrapper .user .info ul>li>a .sidebar-mini {
    /* width: 24px;
    margin-right: 10px; */
}

.sidebar .nav p {
    font-weight: 700;
}

.bootstrap-switch.bootstrap-switch-off .bootstrap-switch-handle-on,
.bootstrap-switch .bootstrap-switch-handle-off.bootstrap-switch-default {
    background-color: red !important;
    opacity: 1;
}

.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-handle-on.bootstrap-switch-primary,
.bootstrap-switch.bootstrap-switch-on .bootstrap-switch-handle-off.bootstrap-switch-default {
    background-color: green !important;
    opacity: 1;
}

.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-on,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-handle-off,
.bootstrap-switch.bootstrap-switch-disabled .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-readonly .bootstrap-switch-label,
.bootstrap-switch.bootstrap-switch-indeterminate .bootstrap-switch-label {
    opacity: 1;
}

.report-list-card {
    width: 100%;
}

.report-list {
    padding-left: 0px;
}

.report-list li {
    display: flex;
    justify-content: space-between;
    margin-bottom: 5px;
    padding-bottom: 5px;
    border-bottom: 1px solid #eee;
}

.report-list li a {
    display: block;
    min-width: 40px;
    text-align: center;
    background: #1088ff;
    color: #fff;
}

.dr_msg.bgred {
    width: 24px;
    height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
    min-width: 24px;
}

.validation_error {
    font-size: 12px;
    color: red;
}

.print-btn-wrapper.print-btn-right.is-no-data {
    top: 209px !important;
    right: 30px;
}
/* .react-transform-component.transform-component-module_content__FBWxo {
    margin: 0 auto;
} */
.more-button:last-child ul.dropdown-with-icons.dropdown-menu {
    top: -174px;
}


@media (max-width: 1600.98px) {
    .aside-card {
        padding: 10px 12px 5px 12px;
    }

    .aside-cards {
        gap: 15px;
    }
}

@media (max-width: 1399.98px) {
    .doctor-info-inner {
        gap: 30px;
    }

    .aside-card {
        padding: 10px 12px 0 12px;
    }

    .aside-card .card-icon {
        margin-bottom: 0;
    }

    .aside-card h4 {
        margin-bottom: 0;
    }

    .aside-cards {
        gap: 10px;
    }

    .ad-carousel {
        margin-top: 10px;
    }

    #search-type .form-check label {
        margin-left: 6px;
    }

    .dashboard-table .calnder {
        min-width: 200px;
    }
}

@media only screen and (max-width:1280.98px) {}

@media (max-width: 1199.98px) {
    .general-information {
        flex-wrap: wrap;
    }

    .knowledge-share div:last-child {
        gap: 10px;
    }
}

@media (max-width: 1080.98px) {
    .user-name-log>div {
        gap: 5px;
    }

    .dashboard .dashboard-grid {
        display: block;
    }

    .ad-carousel {
        margin-top: 0;
    }

    .dashboard .aside-panel {
        display: grid;
        margin-top: 20px;
        grid-template-columns: repeat(2, 1fr);
        gap: 20px;
    }

    .aside-card {
        height: 100%;
        align-items: center;
    }

    .main-panel .main-content.dashboard-2-0 {
        padding: 30px 0;
    }

    nav.front-head.navbar.navbar-default {
        padding: 10px 0;
    }

    .suggetion-box-enlarge .suggestion-lists .form-check {
        width: 50%;
    }

    .emr-tab-content .center-content-table {
        overflow-x: auto;
    }

    .tabs-inside-tabs {
        width: 250px;
    }

    .tabs-inside-tabs-content {
        width: calc(100% - 250px);
    }

    .clinic-preferences-tab .card,
    .prescription-tab .card {
        padding-top: 0 !important;
    }
}

@media (min-width: 991px) {
    .front-nav ul.nav.navbar-nav.navbar-right li {
        margin: 1px;
    }

    .table-responsive {
        overflow-x: auto;
        overflow-y: hidden;
    }

    .clinic-password-management-tab,
    .form-horizontal .control-label {
        padding-top: 0 !important;
    }

    .settings-userlist .table-responsive {
        overflow: auto;
    }

    .resp-t.table-responsive.ui-tbles {
        overflow: visible;
    }
}

@media (max-width: 991.98px) {
    .knowledge-share .chart_flexs a.knowledge-share-card {
        max-width: 232px;
    }

    .tabs-btns-group {
        overflow: auto;
        flex-wrap: nowrap;
    }

    .btn-tab {
        min-width: 200px;
    }

    .tabs-inside-tabs {
        width: 200px;
    }

    .tabs-inside-tabs-content {
        width: calc(100% - 200px);
    }

    .dashboard-2-0 .dashboard-grid .dashboard-table .table {
        min-width: 800px;
    }

    .setting-menu {
        display: flex;
        align-items: center;
        width: 100%;
    }

    .off-canvas-sidebar .navbar-collapse {
        height: fit-content !important;
        min-height: 100vh;
    }

    .front-nav ul.nav.navbar-nav li a .setting-menu img {
        margin-right: 20px;
    }

    .navbar-nav .open .dropdown-menu {
        background-color: #f5f5f5;
        padding: 10px 0;
        margin-top: 10px;
    }

    .front-nav ul.nav.navbar-nav li a .setting-menu .caret {
        margin-left: auto;
    }

    .front-nav ul.nav.navbar-nav.navbar-right li {
        width: 100%;
    }

    .emr-tab-content .center-content-table {
        margin: 0;
    }

    .user-name-log {
        flex-direction: column;
        gap: 10px;
    }

    .dashboard-grid {
        grid-template-columns: 1fr;
    }

    .front-nav ul.nav.navbar-nav.navbar-right li.active {
        margin: 0px !important;
    }

    .general-information {
        gap: 0;
        flex-wrap: wrap;
    }

    .emr-wrapper .general-information {
        gap: 20px;
        flex-wrap: wrap;
    }

    .off-canvas-sidebar .navbar-collapse {
        top: 0;
    }

    div.front-nav ul.nav.navbar-nav li a {
        font-size: 12px;
        display: flex;
        gap: 10px;
        align-items: center;
    }

    .front-nav ul.nav.navbar-nav li:nth-child(5) a {
        padding: 0px 8px !important;
        margin: 5px !important;
    }

    span.chatCount {
        position: static;
        width: 18px;
        height: 18px;
        border-radius: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .sidebar,
    .bootstrap-navbar,
    .off-canvas-sidebar .navbar-collapse {
        background-color: #fff;
    }

    .bootstrap-navbar .nav>li.active>a,
    .off-canvas-sidebar .navbar-collapse .nav>li.active>a {
        background: unset;
    }

    .front-nav ul.nav.navbar-nav li a {
        padding: 5px 10px !important;
    }
}

@media (max-width: 850.98px) {
    .tabs-inside-tabs {
        width: 100%;
        display: flex;
        /* flex-wrap: wrap; */
        gap: 5px;
        overflow: auto;
        margin-bottom: 15px;
    }

    .tabs-inside-tabs-content {
        width: 100%;
        border: none;
        padding: 0;
    }

    .tabs-content-inner {
        gap: 0;
    }

    .tabs-content-inner {
        flex-wrap: wrap;
    }

    .tabs-inside-tabs .btn-tab {
        padding: 6px 10px;
    }

    .tabs-inside-tabs .btn-tab {
        width: auto;
        border: 1px solid #eee;
    }

    .tabs-content {
        margin-top: 20px;
    }

    .doctor-info-inner {
        flex-wrap: wrap;
        gap: 20px;
    }

    .dr-image {
        max-width: 100%;
        width: 100%;
    }

    .dr-image img {
        max-width: 300px;
    }
}

@media (min-width: 768px) {
    .history-col-full {
        grid-column-start: 1;
        grid-column-end: 3;
    }
}

@media (max-width: 767.98px) {
    .knowledge-share .chart_flexs a.knowledge-share-card {
        width: calc(50% - 30px);
        max-width: 100%;
    }

    .knowledge-share-card {
        width: calc(33.33% - 30px);
        flex: 1 auto;
    }

    .knowledge-share-card {
        width: calc(50% - 30px);
    }

    .doctor-info-inner {
        flex-wrap: wrap;
    }

    .tabs-inside-tabs .btn-tab {
        padding: 2px 6px;
    }

    .color-notations .card .flex-box {
        padding: 0 !important;
    }

    .notation-header.notations-title {
        font-size: 15px;
    }

    .tabs-inside-tabs {
        width: 100%;
    }

    .tabs-inside-tabs-content {
        width: 100%;
    }

    .next-calender {
        margin-bottom: 15px;
    }

    .dashboard-grid {
        position: relative;
    }

    .dashboard-2-0 .table-responsive {
        border: none;
    }

    .card-content-inner .emr-menu-list ul {
        white-space: nowrap;
        overflow: auto;
    }

    .card_header_inner ul.patient_details {
        padding: 10px 0;
    }

    .side-modal {
        width: 100%;
        min-width: unset;
        position: absolute;
    }

    .ad-carousel .carousel-indicators {
        visibility: visible;
        bottom: 80px;
        top: auto;
    }

    .history-wrapper {
        display: flex;
        flex-wrap: wrap;
    }

    .suggestion-box .form-check {
        width: 50%;
    }

    .systems-lists .form-check.form-check-inline {
        width: 50%;
    }
}

@media (max-width: 575.98px) {
    .holidays-history-table .react-bs-table-tool-bar .col-xs-6 {
        width: 100%;
    }

    .aside-panel {
        grid-template-columns: repeat(1, 1fr);
    }

    .dashboard .aside-panel {
        grid-template-columns: repeat(1, 1fr);
    }

    .dashboard-2-0 .welcome-user {
        display: block;
    }

    .welcome-user .right-info {
        text-align: left;
        margin-top: 15px;
    }
}

@media (max-width: 480.98px) {
    .suggestion-box .form-check {
        width: 100%;
    }

    .suggetion-box-enlarge .suggestion-lists .form-check {
        width: 100%;
    }

    .systems-lists .form-check.form-check-inline {
        width: 100%;
    }
}