/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

.dietChartTable .react-bs-container-header{
    display: none;
}
.dietChartTable .react-bs-table-tool-bar{
    display: none;
}
.only-print{
    display: none;
}

.pa-diet-screen .modal-lg.modal-dialog {
    right: .5%;
    width: 97%;
    margin-top: 15px;
}
.frontend td.get-diet-details {
    /* display: flex; */
    border-left: none;
    border-top: none;
    padding: 0 !important;
}
.get-diet-details .get-diet-details-inner-div{
/* flex: 1; */
padding: 6px 3px;
padding-left: 20px;
border-left: 1px solid #000;
float: left;
width: 50%;
padding-left: 30px;
}
.get-diet-details .get-diet-details-inner-div:first-child{
    border: none;
    padding-left: 5px;
}

@media print {
    .get-diet-details-inner-div{
        padding-left: 100px !important;
    }
    /* .get-diet-details .get-diet-details-inner-div:first-child{
        padding-left: 5px;
    } */




    /* .get-diet-details > div{
        display: inline-block;
        padding: 5px;
        background-color: #eee;
        text-align: center;
        color: red;
    } */
    .card .header{
        display: none;
    }
    .no-print{
        display: none;
    }
    .only-print{
        display: block;
    }
    .page-break	{ display: block; page-break-before: always; }
    th,td {
        padding: 5px;
        vertical-align: middle;
        border: 1px solid #ddd;
    }


    
}
