/*
Application Name: Practice Aid.
Application URI: https://practice-aid.com/
Description: PracticeAid is a group of professionals with collective expertise in medical and dental science, hospital administration and software development. These experts have come together to work on path-breaking solutions in Healthcare IT and deliver tangible value.
Version: 1.0.0
Author: Practice Aid.
Author URI: https://practice-aid.com/
 “M/s Totall Practice Aid Private Limited, CIN U72900MP2022PTC059139”
*/

.doctor-info span:nth-child(1) {
    color: #5080af;
    font-weight: bold;
    font-size: 15px;
    float: right;
    white-space: nowrap;
    width: 116px;
    overflow: hidden;
    text-overflow: ellipsis;
}

.card-stats .icon-big i {
    float: left;
}

.doctor-info span:nth-child(3) {
    color: #f44336;
    font-weight: bold;
    font-size: 14px;
    text-align: right;
    float: right;
}

.front-nav ul.nav.navbar-nav li a {
    font-size: 10px;
    text-align: center;
    color: #000 !important;
}

.front-nav ul.nav.navbar-nav li a i {
    font-size: 30px;
    text-align: center;
    color: #5080af;
}

nav.front-head.navbar.navbar-default {
    border-bottom: solid 2px #eb8931;
}

.front-nav ul.nav.navbar-nav.navbar-right li {
    position: relative;
}

.front-nav ul.nav.navbar-nav.navbar-right li.active {
    background: #b6b6b6 !important;
}

.col-md-5.col-sm-12.text-right.historybtn {
    padding-right: 40px;
    margin-top: 4px;
}


/* .front-nav ul.nav.navbar-nav.navbar-right li::after{
    border-right: solid 1px #ccc;
    position: absolute;
    content:"";
    height: 60px;
    height: 83px;
    top: 0;
    right: -8px;
} */

.share-popover {
    display: none;
    background-color: rgba(0, 0, 0, 0.5);
    color: #fff;
    font-size: 12px;
    padding: 10px;
    position: absolute;
    top: -40px;
    left: 0;
}

#share:hover+.share-popover {
    display: block;
}

.front-nav ul.nav.navbar-nav.navbar-right li:last-child {
    border: none;
}

.front-nav .dropdown-menu>li>a {
    opacity: 1 !important;
    color: #fff !important;
}

.front-nav .navbar-nav>li>a {
    padding: 0px 13px !important;
    margin: 5px 0 !important;
}

.block-namenav {
    max-width: 174px;
}

.menu-f .navbar-nav>li>a {
    padding: 10px 18px !important;
    margin: 17px 7px !important;
}

nav.navi-bar.navbar.navbar-default {
    border-bottom: solid 2px #fff;
    margin-bottom: 0px;
}

.navi-bar ul.nav.navbar-nav.navbar-right li::after {
    display: none;
}

.navi-bar ul.nav.navbar-nav li a {
    font-size: 15px;
    text-align: center;
    color: #1b6cbe;
}

.frontpage-logo {
    width: 145px;
    padding-top: 10px;
    max-height: 80px;
    padding-left: 10px;
}

.logo-h {
    width: 54px;
    padding-top: 0px;
    max-height: 54px;
}

.navi-bar .dropdown-menu>li>a {
    padding: 6px 18px !important;
}

b.pa-title {
    color: #2a62b1;
    text-transform: capitalize;
    max-width: 400px;
    font-size: 15px;
    margin-left: 0px;
    display: inline-block;
    vertical-align: middle;
    width: 137px;
    text-align: center;
    line-height: 22px;
}

.flccx-header {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
}

.doctor-info,
.doctor-icon {
    padding-top: 2px;
    padding-bottom: 0px;
}

.dpSize img {
    width: 39px !important;
    height: 39px;
    border-radius: 100%;
}

.dot_red {
    height: 10px;
    width: 10px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
}

.dot_black {
    height: 10px;
    width: 10px;
    background-color: #000;
    border-radius: 50%;
    display: inline-block;
}

.dot_green {
    height: 10px;
    width: 10px;
    background-color: #03925e;
    border-radius: 50%;
    display: inline-block;
}

.dot_pink {
    height: 10px;
    width: 10px;
    background-color: #ff1493;
    border-radius: 50%;
    display: inline-block;
}

.dot_blue {
    height: 10px;
    width: 10px;
    background-color: #0000ff;
    border-radius: 50%;
    display: inline-block;
}

.doctor-leave {
    height: 10px;
    width: 10px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
}

.calender-week-off {
    height: 10px;
    width: 10px;
    background-color: #ff00ae;
    border-radius: 50%;
    display: inline-block;
}

.calender-other {
    height: 10px;
    width: 10px;
    background-color: #00f;
    border-radius: 50%;
    display: inline-block;
}

.patient-out-doctor {
    height: 10px;
    width: 10px;
    background-color: #f6db75;
    border-radius: 50%;
    display: inline-block;
}

.patient-out-edu {
    height: 10px;
    width: 10px;
    background-color: #383838;
    border-radius: 50%;
    display: inline-block;
}

.task-pending {
    height: 10px;
    width: 10px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
}

.task-view {
    height: 10px;
    width: 10px;
    background-color: #0000ff;
    border-radius: 50%;
    display: inline-block;
}

.task-completed {
    height: 10px;
    width: 10px;
    background-color: #008000;
    border-radius: 50%;
    display: inline-block;
}

.video-shared-doctor {
    height: 10px;
    width: 10px;
    background-color: #ff0000;
    border-radius: 50%;
    display: inline-block;
}

.video-shared-dietitian {
    height: 10px;
    width: 10px;
    background-color: #ff1493;
    border-radius: 50%;
    display: inline-block;
}

.dr_msg {
    border-radius: 10px !important;
    text-align: center;
    font-size: 11px !important;
}

.notation-Col p {
    font-size: 14px !important;
}

.btn-warning.btn-fill {
    margin-right: 0px;
    background-color: #ef7510;
}

.historybtn .btn-primary.btn-fill {
    color: #0071d2;
    background-color: #fff;
    border-color: #fff;
}

.historybtn .btn-primary.btn-fill:hover,
.historybtn .btn-primary.btn-fill:focus {
    color: #0071d2;
    background-color: #fff;
    border-color: #fff;
}

.nav-tabs>li.active>a,
.nav-tabs>li.active>a:focus,
.nav-tabs>li.active>a:hover {
    border-bottom: 1px solid #ddd;
}

.nav-tabs {
    margin-top: 25px;
}


/*---Asif---*/

.hovertoltip .react-calendar__tile span {
    position: relative;
}

.hovertoltip .react-calendar__tile span:before {
    content: "";
    display: block;
    width: 50px;
    height: 36px;
    position: absolute;
    top: -9px;
    right: -11px;
    cursor: pointer;
}

.bg-bluebox {
    background: #104273;
    padding: 40px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    min-height: 169px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.bg-bluebox.max-w{
    max-width: 555px;
    margin: auto;
    border-radius: 4px;
    background-color: #FFE7E9;
}

.bg-bluebox h4 {
    font-size: 14px;
    line-height: 22px;
}
.whats-app-fixed {
    position: fixed;
    top: 30%;
    right: 5%;
    z-index: 9999;
}
.whats-app-fixed  a {
    display: flex;
    align-items: center;
    gap: 5px;
    background: #61d66c;
    border-radius: 50px;
    padding: 10px;
    color: #fff !important;
    border: 2px solid #eee;
    font-size: 16px;
}
.whats-app-fixed  a svg{
    height: 22px;
}
.whats-app-fixed  a  span{
    line-height: 1;
}

.carousel-indicators {
    visibility: hidden;
}

.carousel-caption {
    top: 36%;
}

.middle-ban-adjust {
    margin-top: -100px;
}

.overlay {
    background-color: #000;
    position: absolute;
    width: 100%;
    height: 100%;
    z-index: 3;
}

.carousel-inner>.item {
    background-color: #000;
}

.carousel-inner>.item img {
    opacity: 0.5;
}

.heading-ban h3 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 36px !important;
    color: #fff;
    text-shadow: 1px 1px #154c83;
}

.heading-ban p {
    font-family: "Open Sans", sans-serif;
    font-size: 16px !important;
    color: #fff;
}

.middle-spc {
    margin-top: 75px;
}

.ourcustom {
    text-align: center;
}

.ourcustom h2 {
    color: #0b598a;
    margin: 0 0 70px 0;
    border-bottom: 1px solid #0b598a;
    display: inline-block;
    padding-bottom: 10px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 30px;
}

.application-d h3 {
    color: #0b598a;
    margin: 35px 0;
    font-size: 24px;
    font-family: "Open Sans", sans-serif;
    border-bottom: 1px solid #0b598a;
    display: inline-block;
    padding-bottom: 10px;
    font-weight: 400;
}

.application-d ul {
    margin: 0;
    padding: 0 0 0 20px;
    color: #0b598a;
}

.application-d ul li {
    font-size: 16px;
    line-height: 32px;
}

.application-d ul li:nth-last-child(1) {
    margin-bottom: 28px;
}

.padd0 {
    padding: 0px !important;
}

.box-img {
    position: relative;
}

.box-img img {
    display: block;
    margin: 0px auto;
}

.text-overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    height: 100%;
    width: 100%;
    opacity: 0;
    transition: 0.3s ease;
    background: rgba(10, 67, 110, 0.8);
}

.box-img:hover .text-overlay {
    opacity: 1;
}

.inner-over {
    color: white;
    font-size: 100px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
    width: 80%;
}

.pera-text p {
    text-align: center;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
}

.footer_section {
    background-image: url(../img/footer_section_img.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    color: #ffff;
    font-family: "Open Sans", sans-serif;
    color: #e7e7e7;
    font-size: 14px;
}

.footer_section:before {
    content: "";
    position: absolute;
    background: #061e36;
    width: 100%;
    height: 100%;
    opacity: 0.9;
}

.footer_section a {
    color: #ffff;
    font-family: "Open Sans", sans-serif;
    color: #e7e7e7;
    font-size: 14px;
}

.footer_section p {
    font-family: "Open Sans", sans-serif;
    font-size: 14px;
    margin-bottom: 0px;
}

.footer_section h6 {
    font-family: "Open Sans", sans-serif;
    text-transform: lowercase;
    font-size: 16px;
    color: #e7e7e7;
}

.show-grid {
    padding-top: 20px;
    padding-bottom: 20px;
}

.footer_section h6::first-letter {
    text-transform: uppercase;
}

.bg-footer {
    color: #e7e7e7;
    background: #061e36;
    position: relative;
    margin-top: 80px;
    padding-top: 20px;
    padding-bottom: 20px;
}

.show-grid img {
    opacity: 0.8;
}

.img-fixheight {
    padding: 0;
    max-height: 430px;
}

.inner-over p {
    font-family: "Open Sans", sans-serif;
}

.carousel-control {
    width: 40%;
}

.spc-align a {
    margin: 10px;
}

.fresh-datatables label {}

.spc-mangess {
    margin-top: 12px;
}

.spc-treport {
    margin-bottom: 15px;
}


/*---About Us---*/

.bg-about-banner {
    background-image: url(../img/banner_3.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}

.bg-about-banner:before {
    content: "";
    position: absolute;
    background: #061e36;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    left: 0;
}

.banner_content {
    padding: 16em 0 17em 0;
    position: relative;
}

.banner_content h2 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 36px !important;
    color: #fff;
    text-shadow: 1px 1px #154c83;
    margin-bottom: 20px;
}

.banner_content p {
    font-family: "Open Sans", sans-serif;
    font-size: 16px !important;
    color: #fff;
    text-align: center;
}

.about-us {
    color: #0b598a;
    display: inline-block;
    font-family: "Open Sans", sans-serif;
    text-align: center;
    width: 100%;
}

.about-us h2 {
    border-bottom: 1px solid #0b598a;
    display: inline-block;
    padding-bottom: 10px;
    margin: 80px 0;
    font-size: 30px;
    font-weight: 400;
    text-align: center;
    font-family: "Open Sans", sans-serif;
}

.pera-about {
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    text-align: center;
    line-height: 30px;
    margin-bottom: 0px;
    color: #0b598a;
}

.pera-about p {
    font-family: "Open Sans", sans-serif;
}

.background_reverse {
    /* background: linear-gradient( to right, rgba(11, 89, 138, 1) 0%, rgba(11, 89, 138, 1) 20%, rgba(11, 89, 138, 1) 42%, rgba(11, 89, 138, 1) 65%, rgba(255, 255, 255, 1) 100%); */
    background: rgba(11, 89, 138, 1);
    color: #fff;
    font-family: "open sans", sans-serif;
    padding: 35px;
    margin: 40px 0;
}

.vision_background {
    display: inline-block;
    width: 100%;
}

.floatright {
    float: right;
    position: relative;
    z-index: 9;
    margin: 40px 0;
    transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
}

.background_reverse p {
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
}
.vision-bg.vision-2 {
    flex-direction: row-reverse;
}

.background_reverse h4 {
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 15px !important;
}

.background_reverse h6 {
    text-transform: inherit;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
}

.background_reverse h6 span {
    font-weight: 600;
}

.colo-bg-reverse {
    float: right;
    position: absolute;
    bottom: 5px;
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient( left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    background: -webkit-gradient( left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(8%, rgba(255, 255, 255, 1)), color-stop(37%, rgba(11, 89, 138, 1)), color-stop(57%, rgba(11, 89, 138, 1)), color-stop(71%, rgba(11, 89, 138, 1)), color-stop(65%, rgba(11, 89, 138, 1)));
    background: -webkit-linear-gradient( left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    background: -o-linear-gradient( left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    background: -ms-linear-gradient( left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    background: linear-gradient( to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    padding: 35px 35px 35px 35%;
    color: #fff;
    font-family: "open sans", sans-serif;
}

.founder-left {
    float: left;
    position: relative;
    z-index: 9;
}

.founder-left img {
    width: 100%;
}

.colo-bg-reverse h4 {
    font-weight: 500;
    font-family: "Open Sans", sans-serif;
    margin-bottom: 24px !important;
}

.colo-bg-reverse p {
    margin: 30px 0 0 0;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    line-height: 25px;
}

.colo-bg-reverse h6 {
    text-transform: inherit;
    font-size: 15px;
    font-family: "Open Sans", sans-serif;
}


/*---Contact us---*/

.banner-contact {
    background-image: url(../img/banner_2.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}

.banner-contact:before {
    content: "";
    position: absolute;
    background: #061e36;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    left: 0;
}

.contact-heading {
    text-align: center;
}

.contact-heading h2 {
    border-bottom: 1px solid #0b598a;
    display: inline-block;
    padding-bottom: 10px;
    margin: 80px 0;
    font-size: 30px;
    color: #0b598a;
    font-weight: 400;
}

.box-input {
    background: #0b598a;
    padding: 45px;
    text-align: left !important;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    opacity: 1;
    position: static;
}

.box-input .card {
    background-color: #ffffff00;
    box-shadow: none;
}

.box-input .header {
    color: #fff !important;
    padding: 0px;
    text-align: left !important;
}

.box-input .form-control {
    border-radius: 0px !important;
    margin-bottom: 35px;
}

.box-input .card .title {
    color: #fff !important;
    padding: 0px 15px;
}

.box-input .card .footer .legend {
    text-align: left;
}

.box-input .btn-info.btn-fill {
    padding: 15px 30px;
    font-size: 16px;
    color: #fff;
    font-family: "Open Sans", sans-serif;
    font-weight: 200;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #fff;
    border-radius: 0px;
    background-color: #0b598a;
    transition: 0.5s all linear;
}

.box-input .btn-info.btn-fill:hover {
    background-color: #fff;
    color: #0b598a;
}

.contact-p p {
    color: #0b598a;
    font-size: 16px;
    font-family: "open sans", sans-serif;
    line-height: 30px;
    text-align: center;
    border-right: 3px solid #0b598a;
    padding: 0 18px;
}

.contact-no p {
    color: #0b598a;
    font-size: 16px;
    font-family: "open sans", sans-serif;
    line-height: 30px;
    padding: 0 18px;
}

.contact-no span {
    font-weight: 600;
}

.PracticeAid-ban {
    background-image: url(../img/banner_2.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}

.PracticeAid-ban:before {
    content: "";
    position: absolute;
    background: #061e36;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    left: 0;
}

.PracticeAid-pera p {
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    text-align: center;
    line-height: 30px;
    margin-bottom: 40px;
    color: #0b598a;
    font-size: 14px;
}

.PracticeAid-pera p span {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
}

.screen1 img {
    display: block;
    margin: 0 auto 20px;
    width: 200px;
    box-shadow: 1px 1px 10px #ccc;
    max-width: 100%;
}

.solutionHighlights h4 {
    color: #0b598a;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 40px !important;
    margin-top: 15px !important;
}

.solutionHighlights ul li {
    list-style: none;
    color: #0b598a;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    line-height: 25px;
    font-size: 16px;
    margin-bottom: 10px;
}

.aahar-ban {
    background-image: url(../img/banner_2.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}

.aahar-ban:before {
    content: "";
    position: absolute;
    background: #061e36;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    left: 0;
}

.m-b-img {
    margin-bottom: 50px;
}


/*.navbar-default .navbar-nav > .active > a, .navbar-default .navbar-nav > .active > a:not(.btn):hover, .navbar-default .navbar-nav > .active > a:not(.btn):focus, .navbar-default .navbar-nav > li > a:not(.btn):hover, .navbar-default .navbar-nav > li > a:not(.btn):focus{
        color: #1b6cbe!important;
}*/

.product-ban {
    background-image: url(../img/banner_1.png);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}

.product-ban:before {
    content: "";
    position: absolute;
    background: #061e36;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    left: 0;
}

.healthcare h4 {
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    color: #0b598a;
    font-size: 18px;
    margin-bottom: 40px !important;
}

.boxhealthcare {
    border: 1px solid #0b598a;
    margin-bottom: 40px;
}

.inner-boxhealthcare {
    background: #0b598a;
    padding: 30px;
    text-align: center;
    color: #fff;
    font-size: 14px;
    font-weight: 100;
    margin: 9px;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.inner-boxhealthcare p {
    font-family: "Open Sans", sans-serif;
}

.full-box {
    position: relative;
    border: 1px solid #276792;
    margin-bottom: 40px;
    height: auto;
}

.box_content {
    padding: 15px;
}

.box_content h4 {
    color: #0b598a;
    font-size: 18px;
    font-family: "Open Sans", sans-serif;
    font-weight: 600;
    margin: 20px 0 !important;
}

.box_content p {
    color: #0b598a;
    font-size: 14px;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
}

.btnmore a {
    padding: 15px 30px;
    font-size: 16px;
    color: #0b598a;
    font-family: "Open Sans", sans-serif;
    font-weight: 100;
    margin: 20px 0;
    display: inline-block;
    text-decoration: none;
    border: 1px solid #0b598a;
    transition: 0.5s all linear;
}

.btnmore a:hover {
    background: #0b598a;
    color: #fff;
    border: none;
    -webkit-transition: background-color 2s ease-out;
    -moz-transition: background-color 2s ease-out;
    -o-transition: background-color 2s ease-out;
    transition: background-color 2s ease-out;
}

.imghover img {
    display: block;
    height: auto;
}

.imghover {
    background-color: #0a456d;
}

.imgoverlay {
    opacity: 0.5;
}

.icon-hover {
    opacity: 0;
    transition: 0.3s ease;
}

.imghover:hover .icon-hover {
    opacity: 1;
}

.icon-hover {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    text-align: center;
}

.privacy-t p {
    font-family: "Open Sans", sans-serif;
}


/*.dropdown-menu > li > a{
    padding: 0px 18px;
}*/

.spc-align {
    text-align: right;
}

.dropdown-menu>li:last-child>a {
    margin-bottom: 6px;
}

.contact-logo {
    padding-top: 60px;
    padding-bottom: 40px;
}

.colo-bg-reverd {
    background: rgba(255, 255, 255, 1);
    background: -moz-linear-gradient( left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    background: -webkit-gradient( left top, right top, color-stop(0%, rgba(255, 255, 255, 1)), color-stop(8%, rgba(255, 255, 255, 1)), color-stop(37%, rgba(11, 89, 138, 1)), color-stop(57%, rgba(11, 89, 138, 1)), color-stop(71%, rgba(11, 89, 138, 1)), color-stop(65%, rgba(11, 89, 138, 1)));
    /* background: -webkit-linear-gradient(left, rgba(255,255,255,1) 0%, rgba(255,255,255,1) 8%, rgba(11,89,138,1) 37%, rgba(11,89,138,1) 57%, rgba(11,89,138,1) 71%, rgba(11,89,138,1) 65%); */
    background: -o-linear-gradient( left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    background: -ms-linear-gradient( left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    background: linear-gradient( to right, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 8%, rgba(11, 89, 138, 1) 37%, rgba(11, 89, 138, 1) 57%, rgba(11, 89, 138, 1) 71%, rgba(11, 89, 138, 1) 65%);
    color: #fff;
    font-family: "open sans", sans-serif;
}

.terms-condition p {
    font-size: 14px;
    font-family: "open sans", sans-serif;
}

.vision-bg {
    display: flex;
    gap: 40px;
    width: 100%;

}

.founder-img {
    /* float: right; */
    position: relative;
    z-index: 9;
    margin: 0;
    transform: scaleX(-1);
    -ms-transform: scaleX(-1);
    -webkit-transform: scaleX(-1);
    max-width: 200px;
}

.content-text {
    /* float: right; */
    /* position: absolute;
    bottom: 0; */
    flex: calc(100% - 200px);
}

.founder-img img {
    width: 100%;
    background: #fff;
    border: 1px solid #fff;
}

.mob-touch-right {
    float: right;
    display: block;
    padding: 0px;
}

.bg-colorimg {
    background-color: #104273;
}

.bg-colorimg img {
    opacity: 0.8;
    width: 100%;
    max-height: 430px;
}

.it_service {
    width: 18.6%;
    float: left;
    display: inline-block;
    margin-right: 15px;
    margin-bottom: 15px;
}

.spc-card .card .content {
    padding: 0px 15px 10px 15px;
}

.spc-card legend {
    font-size: 19px;
    font-weight: 600;
}

.spc-card .checkbox,
.radio {
    margin-bottom: 10px;
}

.spc-charts {
    margin-top: -30px;
}

.ico-width img {
    width: 39px;
}

.gKyapV {
    width: 30px;
    height: 30px;
}

.icon-calender {
    position: relative;
    display: inline-flex;
}

.icon-calender img {
    display: inline-block;
    vertical-align: top;
    width: 27px;
    max-height: 27px;
    margin-left: 6px;
}

.icon-calender:after {
    position: absolute;
}

.notification-title {
    top: 12% !important;
}

.heJwJj {
    height: 335px;
}

.card .table tbody td:last-child,
.card .table thead th:last-child {
    outline: none;
}

.table>thead>tr>th,
.table>tbody>tr>th,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>tbody>tr>td,
.table>tfoot>tr>td {
    outline: none;
}

.or-align {
    text-align: center;
    text-transform: uppercase;
    margin-bottom: 15px;
    margin-top: 7px;
    font-weight: 600;
}

.popline-boxes tbody>tr>td {
    border-color: #f9f9f9 !important;
}

.W50.right-lines {
    border-right: 1px solid #ddd !important;
}

.right-lines .row {
    margin-bottom: 5px;
    text-transform: uppercase;
}

.sapcelineheight .row {
    margin-bottom: 5px;
    text-transform: uppercase;
}

.W50.sapcelineheight .checkbox label,
.radio label {
    line-height: 16px;
    font-size: 11px;
    padding-left: 20px;
}

.scroltable tr:nth-of-type(2) {
    overflow-y: scroll;
    height: 280px;
}

.padd0min .col-sm-12 {
    margin: 0px 0px 7px !important;
    display: flex;
    align-items: center;
    width: 100%;
    position: relative;
}

.padd0min .fa {
    width: 18px;
    text-align: right;
    position: absolute;
    right: 0px;
}


/* Asif*/

.bg-boxess-doctor {
    background: #ffffff;
    padding: 15px;
    /* margin-top: 10px; */
    border-radius: 4px;
    -webkit-box-shadow: 0px 0px 15px 1px #ccc;
    -moz-box-shadow: 0px 0px 15px 1px #ccc;
    -o-box-shadow: 0px 0px 15px 1px #ccc;
    -ms-box-shadow: 0px 0px 15px 1px #ccc;
    box-shadow: 0px 0px 15px 1px #ccc;
    position: relative;
    top: 0px;
}

.bg-boxess-doctor .table-condensed thead {
    /* background-color: #EF7510;
    color: #fff; */
}

.bg-boxess-doctor .table th {
    /* text-align: center !important; */
    /* color: #fff; */
    font-size: 14px;
    font-weight: 600;
}

.bg-boxess-doctor table tr td span {
    /*color: #000000;*/
    font-weight: 500;
    cursor: pointer;
}

.form-liness-outline .form-control {
    border: 1px solid #c1c1c1;
    width: 140px;
    border-radius: 10px;
}

.form-liness-outline.w550.sm-wwi.btn-Mng.col-md-4.col-sm-3 {
    padding-left: 0;
}

.color-bar-btom {
    position: absolute;
    left: 0;
    right: 0;
}

.color-bar-btom>div:nth-child(1) {
    background: #6bad4a;
}

.color-bar-btom>div:nth-child(1) {
    border-radius: 4px 0 0 0;
}

.color-bar-btom>div {
    height: 5px;
    display: block;
}

.color-bar-btom>div:nth-child(2) {
    background: #f7af33;
}

.color-bar-btom>div:nth-child(3) {
    background: #ef7510;
}

.color-bar-btom>div:nth-child(4) {
    background: #368ac8;
}

.color-bar-btom>div:nth-child(4) {
    border-radius: 0 4px 0 0;
}

.spc-Radio .radio {
    margin-top: 0px !important;
    margin-bottom: 1px !important;
}

.check-spac .checkbox {
    margin-top: 1px !important;
}

.spc-textstop {
    margin-bottom: 15px;
    text-transform: none;
    font-size: 14px;
    margin-top: 8px;
}

.wi50 {
    width: 50%;
    float: left;
    padding-left: 21px;
}

.labelbt-spc label {
    font-size: 13px;
}

.noneline td {
    border-bottom: none !important;
}

.pad15educator {
    padding: 15px;
}

.alingtdwidht td {
    width: 50%;
}

.lineremove legend {
    border-bottom: none !important;
}

.grey-bgboxdiet {
    background-color: #f5f5f5;
    padding: 10px;
}

.checkbox,
.radio {
    margin-bottom: 5px !important;
}

.topmessag {
    margin-top: 0px;
    margin-bottom: 0px;
    line-height: 22px;
}

.topD {
    margin-top: 7px;
    margin-bottom: 0px;
}

.bg-Diets {
    background-color: #efefef;
    border-left: 5px solid #ef7510;
    border-right: 5px solid #ef7510;
    border-radius: 4px 4px 0px 0px;
    padding: 5px;
}

.bg-Diets button {
    margin-top: 2px;
}

.card0pad .card .content {
    padding-top: 0px;
}

.spc-tascreans .panel {
    background-color: #fff0 !important;
    margin-bottom: 0px;
}

.spc-tascreans .nav-tabs {
    margin-top: 2px !important;
}

.spc-tascreans #tabs-with-dropdown .nav-tabs>li>a {
    padding: 11px 21px;
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0px;
    line-height: 1px;
}

.spc-tascreans .panel-default>.panel-heading {
    background-color: #ffffff00 !important;
    border-color: #ffffff00 !important;
}

.sections-scrol {
    background-color: #efefef;
    overflow-y: auto;
    padding: 5px;
}

.padding-5 legend {
    font-size: 15px;
    font-weight: 600;
    margin-bottom: 6px;
    text-transform: uppercase;
}

.tabcont-spc {
    padding: 5px;
    margin-bottom: 15px;
    border: 1px solid #dad9d9;
}

.spc-Radio {
    padding: 0px !important;
}

.spc-tascreans #tabs-with-dropdown .nav-tabs>li.active>a {
    background: #00a4bf;
    color: #fff;
}

.spc-tascreans .nav-tabs>li>a {
    border: 1px solid #efefef;
}

.spc-tascreans #tabs-with-dropdown .nav-tabs>li>a {
    color: #292929;
}

.doctor-popss>div {
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    padding: 0 0px;
}

.doctor-popss>div .col-sm-2 {
    width: 16%;
    margin: 0px;
    margin-bottom: 0px;
    font-size: 11px;
    margin-right: 6px;
}

.subbtn .btn-warning.btn-fill {
    /* margin-right: 41px; */
}

.knowledge-share .subbtn .btn-fill {
    margin-right: 0;
}

.table-responsive-new .table-condensed {
    min-width: 800px;
}

.spc-btom {
    color: #000;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.spc-btom span {
    margin-left: 9px;
    margin-right: 9px;
}

.crosscalo button.close {
    z-index: 999;
    position: absolute;
    right: 7px;
    font-size: 28px;
    margin: 7px;
    opacity: 1;
    color: #fff;
    top: 1px;
}

.pa-diet-screen h4 {
    color: #000;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.pa-diet-screen .close {
    z-index: 999;
    position: absolute;
    right: 7px;
    font-size: 28px;
    margin: 7px;
    opacity: 1;
    color: #000;
    top: 6px;
}

.diet-screen .nav-tabs {
    margin-top: 2px !important;
}

.diet-screen #tabs-with-dropdown .nav-tabs>li>a {
    padding: 11px 21px;
    text-transform: uppercase;
    font-weight: normal;
    border-radius: 0px;
    line-height: 1px;
    color: #122bac;
    background-color: #f5f5f5;
}

.diet-screen #tabs-with-dropdown .nav-tabs>li.active>a {
    color: #ffffff;
    background: #1174d7;
    border-radius: 0;
    border: 1px solid #1174d7;
    border-top: none;
}

.diet-screen .panel {
    border-bottom: 1px solid #fff;
}

.sections-dietss {
    background-color: #fff;
    overflow-y: auto;
    height: 200px;
    padding: 15px;
    margin-bottom: 16px;
}

.sections-dietss legend {
    border-bottom: 1px solid #fff !important;
}

.m-bR .radio {
    margin-bottom: 10px !important;
}

.lisulahar ul {
    margin: 0px;
    padding: 0px;
}

.lisulahar ul li {
    list-style-type: none;
}

.next-Arrow {
    margin-top: 60px;
}

.checkbox label::before,
.checkbox label::after {
    color: #ef7510;
    font-size: 15px;
}

.relat {
    position: relative;
}

.pdf-ri {
    position: absolute;
    right: 14px;
    top: 5px;
    color: #333;
    border: 1px solid #0592af;
    padding: 2px;
    border-radius: 4px;
    font-size: 13px;
}

.pdf-ri:hover {
    background: #0592af;
}

.pdf-ri:hover i {
    color: #fff;
}

.top-mdirt {
    margin-top: 20px;
}

.top-mdirt label {
    padding-top: 10px;
    margin-bottom: 0;
    float: right;
}

.center-mg {
    text-align: center;
    margin: 0px auto;
}

.center-mg img {
    width: 100%;
}

.bt-rights {
    text-align: right;
    width: 100%;
}

.small-Input {
    width: 45px;
}


/* ditebar css start*/

.ditebar {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    justify-content: space-between;
    -webkit-justify-content: space-between;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    width: 100%;
}

.ditebar .row {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    margin: 0 !important;
}

.ditebar button {
    margin: 0 !important;
}

.ditebar .col-sm-12 {
    padding: 0;
    width: auto !important;
}

.ditebar div {
    width: auto !important;
}

.ditebar div.spcmobsn h4 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 2px !important;
    line-height: 20px;
}

#react-confirm-alert {
    text-align: center;
}

#react-confirm-alert .react-confirm-alert-body {
    font-size: 18px;
    color: #000;
    text-align: center;
}

#react-confirm-alert .react-confirm-alert-body h1 {
    font-size: 20px;
    font-weight: 600;
    color: #000;
}

#react-confirm-alert .react-confirm-alert-button-group {
    justify-content: center;
}

#react-confirm-alert .react-confirm-alert-body .e-consultation {
    justify-content: center;
    text-align: left;
}

.react-confirm-alert-body.w-300 {
    width: 300px;
}

#react-confirm-alert .react-confirm-alert-body .e-consultation .consultation-radio {}

#react-confirm-alert .react-confirm-alert-body .e-consultation .consultation-radio .form-group {
    padding: 5px 10px;
    background-color: #f5f5f5;
    border-radius: 10px;
    margin-bottom: 5px;
}

#react-confirm-alert .react-confirm-alert-body .e-consultation .consultation-radio .form-group input,
#react-confirm-alert .react-confirm-alert-body .e-consultation .consultation-radio .form-group label {
    cursor: pointer;
}

.ditebar .panel-heading {
    padding: 0 !important;
}

.ditebar>div:first-child,
.ditebar .spcmobsn.mobmange {
    margin-right: 10px;
}

.ditebar .row>div {
    margin-right: 10px;
}

.ditebar .weight-case,
.ditebar .notblink-cases {
    padding: 2px 8px 1px;
}

.ditebar>.row {
    width: calc(100% - 168px) !important;
    justify-content: space-around;
}

.dite_blue_box,
.dite_btn_group {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
}

.dite_blue_box>div,
.dite_btn_group>div {
    margin: 0 6px;
}

.ditebar select.form-control[name="calorie"] {
    width: 220px;
}

.list-ww .react-bs-table-tool-bar .col-xs-6 {
    width: 100% !important;
}

.list-ww .react-bs-table-tool-bar .col-xs-6 .form-group {
    margin: 0px;
}

.Radio-mmd .radio label {
    line-height: 18px !important;
}

.pad1155 {
    padding: 0px 10px;
}

.fxl {
    display: flex;
}


/* ditebar css end*/

@media screen and (max-width: 767px) {
    .doctor-popss .col-md-1 {
        width: 100%;
        text-align: center;
    }
    .ml-10 {
        padding-left: 10px !important;
    }
    .subbtn {
        text-align: center !important;
    }
    .lineremove.flexaling {
        margin: 10px auto;
        margin-bottom: 16px;
    }
    .chart_flexs {
        flex-wrap: wrap;
    }
    .knowledge-share .chart_flexs a {
        margin-bottom: 15px !important;
    }
    .bg_greysectionss button {
        margin-bottom: 10px;
        margin-top: 5px;
    }
    .ditebar {
        display: inline-block;
    }
    .visit-list .react-bs-table-tool-bar .col-xs-6 {
        width: 100% !important;
    }
    .spcmobsn.mobmange {
        float: left;
    }
    .spc-tascreans .panel-default>.panel-heading {
        margin: 11px 0px;
    }
    .w550 {
        width: 50%;
        float: left;
    }
    .card {
        margin-top: 15px;
    }
    .front-nav ul.nav.navbar-nav.navbar-right li:last-child {
        border: none;
        width: 100%;
        margin-left: 0;
    }
    .pad1155 {
        padding: 0px;
    }
    .pa-patient-registration {
        padding-left: 0 !important;
    }
    .report-topp {
        margin-top: 15px;
    }
    .list_dropss .card h4 {
        padding: 5px !important;
    }
    .graph-textalign {
        padding: 0px 0px 20px;
    }
    .bgwhite_graph {
        margin-bottom: 0px;
    }
    .aling-Flexs {
        display: -webkit-box;
    }
    .paddi5 .react-bs-table-search-form {
        padding: 5px !important;
    }
    .notation-title {
        margin-top: 15px !important;
    }
    .fresh-datatables span.dropdown.react-bs-table-sizePerPage-dropdown {
        padding: 0px 5px;
    }
    .mTpv {
        margin-top: 10px;
    }
    .msd-top {
        margin-top: 8px;
    }
    .menuout-home {
        font-size: 15px;
        padding: 0px 11px !important;
        text-align: left;
        color: #fff !important;
    }
    .spc_btm .content .col-sm-3,
    .spc_btm .form_panel .col-sm-6,
    .spc_btm .form_panel .col-sm-3 {
        margin-bottom: 10px;
    }
    .menuout-home ul.nav.navbar-nav li a {
        font-size: 15px;
        text-align: left;
    }
    .menuout-home .open .dropdown-menu>li>a {
        opacity: 1;
        background: transparent;
        color: #fff !important;
        font-weight: 600;
        text-transform: uppercase;
        margin-left: 12px;
    }
    .menuout-home>li>a.dropdown-toggle,
    .bootstrap-navbar .nav>li>a.dropdown-toggle,
    .off-canvas-sidebar .navbar-collapse .nav>li>a.dropdown-toggle {
        margin-bottom: 0px !important;
    }
    .bg-grymbs {
        background-color: #444444;
    }
}

.crosscalo.modal-header {
    padding: 6px 15px;
    background: #1174d7;
}

.crosscalo.modal-header h4 {
    color: #fff;
}

.modalcaloriess {
    margin: 72px auto;
}

.bg-headershare {
    padding: 6px 15px;
    background: #1174d7;
}

.bg-headershare h4 {
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.bg-headershare button.close {
    z-index: 999;
    position: absolute;
    right: 7px;
    font-size: 28px;
    margin: 7px;
    opacity: 1;
    color: #fff;
    top: 0px;
}

.top-patient {
    margin: 70px auto;
}

.header-dietpop {
    padding: 6px 15px;
    background: #1174d7;
}

.radio label::before,
.radio label::after {
    color: #ef7510;
    font-size: 15px;
    line-height: 15px;
}

.sections-dietss .radio input[type="radio"]:checked+label:after,
.radio input[type="radio"]:checked:not(:disabled):hover+label:after {
    color: #ef7510;
}

.educator-header .header {
    padding: 6px 15px;
    background: #1174d7;
    margin-bottom: 15px;
}

.educator-header .header h4 {
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.header-dietpop h4 {
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.header-dietpop button.close {
    z-index: 999;
    position: absolute;
    right: 7px;
    font-size: 28px;
    margin: 7px;
    opacity: 1;
    color: #fff;
    top: 0px;
}

.bg-thumbcolor {
    padding: 6px 15px;
    background: #0d72d7;
    margin-bottom: 15px;
}

.bg-thumbcolor h4 {
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.bg-thumbcolor button.close {
    z-index: 999;
    position: absolute;
    right: 7px;
    font-size: 28px;
    margin: 7px;
    opacity: 1;
    color: #fff;
    top: 0px;
}

.header-designsahar {
    padding: 6px 15px;
    background: #1174d7;
    margin-bottom: 15px;
}

.header-designsahar h4 {
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.header-designsahar button.close {
    z-index: 999;
    position: absolute;
    right: 7px;
    font-size: 28px;
    margin: 7px;
    opacity: 1;
    color: #fff;
    top: 0px;
}

.Patient-headers {
    padding: 6px 15px;
    background: #1174d7;
    margin-bottom: 15px;
}

.Patient-headers h4 {
    color: #fff;
    font-weight: 600;
    font-size: 17px;
    text-transform: capitalize;
    padding: 4px;
    margin-right: 0px;
    margin-left: -4px;
    margin-bottom: 0px !important;
}

.Patient-headers button.close {
    z-index: 999;
    position: absolute;
    right: 7px;
    font-size: 28px;
    margin: 7px;
    opacity: 1;
    color: #fff;
    top: 0px;
}

.card {
    box-shadow: none;
}

.main-panel .main-content {
    position: relative;
}

.spin-loader {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;
}

.spin-loader svg {
    width: 32px;
    height: 32px;
    fill: white;
}

@media only screen and (min-width: 992px) {
    .pad15educator .col-md-2 {
        padding-right: 0px;
    }
    .spc-rr .col-sm-3 {
        padding-right: 0 !important;
    }
    .main-panel .main-content {
        min-height: calc(100vh - 157px) !important;
    }
}

@media screen and (max-width: 767px) {
    .padmomb .col-md-12 {
        padding: 0px !important;
    }
    .list-ww .react-bs-table {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
    .no-padding-left {
        padding-left: 10px !important;
    }
    .no-padding-right {
        padding-right: 10px !important;
    }
    .middle-ban-adjust {
        margin-top: 0px;
    }
    .bg-bluebox {
        min-height: 120px;
        margin-bottom: 15px;
    }
    .heading-ban h3 {
        font-size: 19px !important;
    }
    .msd-top.col-sm-3 div {
        display: inline-block;
        margin-right: 15px;
    }
    .carousel-caption {
        top: 0% !important;
    }
    .carousel-caption h3 {}
    .hm-blk .hm-icons {
        width: 95%;
        margin-bottom: 15px;
    }
    .heading-ban p {
        font-size: 10px !important;
    }
    .ourcustom h2 {
        font-size: 20px;
    }
    .application-d h3 {
        font-size: 18px;
    }
    .spc-align {
        text-align: left;
    }
    .footer_section p {
        margin-bottom: 10px;
    }
    .carousel-control {
        width: 17% !important;
    }
    .sm-w {
        width: 100% !important;
    }
    .solutionHighlights ul li {
        font-size: 14px;
    }
    .solutionHighlights h4 {
        margin-bottom: 25px !important;
        margin-top: 0px !important;
    }
    .about-us h2 {
        font-size: 25px;
        margin: 40px 0;
    }
    .box-input {
        padding: 21px;
    }
    .bg-mob {
        background: #0b598a;
        padding: 17px;
    }
    .spc-imges {
        margin-bottom: 20px;
    }
    .founder-img,
    .content-text {
        float: none;
        position: static;
        margin: -1px 0;
    }
    .colo-bg-reverse,
    .founder-left {
        position: static;
        float: none;
    }
    .founder-img img {
        width: 100% !important;
        margin-top: 30px;
    }
    .founder-left img {
        width: 100% !important;
    }
    .wi50 {
        width: 100% !important;
        padding-left: 0px !important;
    }
    .hiddelie .table-responsive {
        border: none !important;
    }
    .botom-icons .col-sm-2 {
        width: 89% !important;
        margin-bottom: 18px;
    }
    .spcfields {
        margin-top: 15px;
    }
    .spcmobsn {
        margin-bottom: 15px;
    }
    .pa-task-screen .modal-body {
        overflow-y: auto;
        height: 530px;
        overflow-x: hidden;
    }
    .mobmange .btn-fill {
        margin-right: 0px;
    }
    .m0b15 {
        margin-bottom: 15px;
    }
    .t-left {
        text-align: left !important;
        margin-left: 15px;
    }
}

@media only screen and (max-width: 768px) {
    .middle-ban-adjust {
        margin-top: 0px;
    }
    .spcmobsn {
        margin-bottom: 15px;
    }
    .bg-bluebox {
        background: #104273;
        padding: 36px;
        min-height: auto;
        margin-bottom: 15px;
    }
    .sm-w {
        width: 33.33333333%;
    }
    .contact-p p {
        border-right: none;
    }
    .contact-no {
        text-align: center;
        margin-top: 15px;
    }
    .mob-touch-right {
        float: none;
    }
    .content-text {
        float: none;
        position: static;
    }
    .colo-bg-reverse {
        float: none;
        position: static;
    }
    .background_reverse p {
        font-size: 14px;
    }
    .colo-bg-reverse p {
        font-size: 14px;
    }
    .founder-img img {
        width: 92%;
    }
    .founder-left img {
        width: 92%;
    }
}

@media only screen and (max-width: 1024px) {
    .bg-bluebox {
        padding: 34px;
    }
    .spc-card legend {
        font-size: 15px;
    }
    .mobmange .btn-warning.btn-fill {
        margin-right: 0px;
    }
    .sm-w {
        width: 33.33333333%;
    }
    .carousel-caption {
        top: 22%;
    }
    .carousel-control {
        width: 28%;
    }
    .founder-img img {
        width: 86%;
    }
    .founder-left img {
        width: 86%;
    }
    .dr-admin ul.nav.navbar-nav.navbar-right li:last-child {
        position: absolute;
        right: 0px;
        top: 107px;
    }
    .dr-admin ul.nav.navbar-nav li a {
        font-size: 14px;
    }
    .front-nav ul.nav.navbar-nav.navbar-right li::after {
        height: 70px;
    }
    .front-nav ul.nav.navbar-nav li a {
        font-size: 11px;
        padding: 0px 11px !important;
    }
    .doctor-info span:nth-child(1) {
        font-size: 12px;
    }
    .doctor-info span:nth-child(3) {
        font-size: 11px;
    }
    b.pa-title {
        font-size: 14px;
        margin-left: 6px;
    }
}

@media (max-width: 1317px) {
    .block-namenav {
        max-width: 167px;
    }
    .inlin-tt a {
        font-size: 13px;
    }
    .mange-nt input {
        max-width: 217px;
    }
    .fl-rr {
        width: 36%;
    }
    .reduce-w {
        width: 60%;
    }
}

@media (max-width: 1300px) {
    .inlin-tt a {
        font-size: 13px;
    }
    .mange-nt input {
        max-width: 217px;
    }
    .fl-rr {
        width: 36%;
    }
    .reduce-w {
        width: 60%;
    }
}

@media (max-width: 992px) {
    .comment-form .row {
        width: 100%;
    }
    .open .dropdown-menu {
        position: absolute;
    }
    .inlin-tt a {
        font-size: 13px;
    }
    .mange-nt input {
        max-width: 217px;
    }
    .dd-none {
        display: block;
    }
    html,
    body {
        overflow-x: hidden;
    }
}

@media only screen and (max-width: 1199px) {
    .knowledge-share>.row>div {
        width: auto !important;
    }
    .knowledge-share .chart_flexs a {
        /* width: auto !important; */
        padding-left: 8px !important;
        padding-right: 8px !important;
        min-width: 98px !important;
        margin: 0 5px !important;
    }
    .bg_greysectionss>div {
        width: 100%;
    }
    .bg_greysectionss {
        display: block;
        text-align: center;
    }
    .bg_greysectionss .Select_sec {
        width: 33.33%;
    }
    .bg_greysectionss button {
        width: calc(20% - 12px);
        margin: 0 6px;
    }
}

@media only screen and (max-width: 1199px) and (min-width: 992px) {
    .rdt input {
        font-size: 13px;
        padding: 0px 3px;
    }
    .ss-r {
        min-width: 79px !important;
    }
    .botom-icons>div {
        padding: 0 10px !important;
    }
    .pad15educator .col-md-2 {
        padding-right: 0px !important;
    }
    .block-namenav {
        max-width: 100%;
    }
    b.pa-title {
        width: auto;
    }
    .ico-width {
        margin: 7.5px -16px;
    }
    /*     .ico-width li {
    margin: 5px 7px 6px 10px!important;
   }*/
    .bg-pend-clinic .card.card-stats {
        min-height: 164px;
    }
    .educator-L .react-bs-table,
    .table-responsive-new .react-bs-table {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
    .fuls-how-m .col-sm-7 {
        max-width: 100% !important;
        width: 100%;
        margin-bottom: 8px;
        padding-left: 0px !important;
    }
    .right-rr {
        margin-right: 0px;
    }
    .right-rr button {
        width: 63px;
        padding: 5px;
        height: 40px;
        margin: 0px 11px 0px 0px;
    }
    .fuls-how-m button {
        margin-left: -5px !important;
    }
    .headCl .btn-toolbar button {
        margin-bottom: 8px;
    }
    .it_service {
        width: 18.4%;
    }
    .bg-bluebox {
        min-height: 165px;
        padding: 30px;
    }
    .hm-blk .hm-icons {
        width: 19.7%;
    }
    .Select_sec {
        margin-bottom: 10px;
    }
    .bg_greysectionss button {
        margin-bottom: 10px;
    }
}

@media only screen and (max-width: 991px) and (min-width: 768px) {
    .flccx-header .navbar-header {
        width: 100%;
    }
    .padmomb .col-md-12 {
        padding: 0;
    }
    .ss-r {
        min-width: 78px !important;
    }
    .pl10 {
        padding-left: 14px !important;
    }
    .no-padding-left {
        padding-left: 10px !important;
    }
    .no-padding-right {
        padding-right: 10px !important;
    }
    .pad15educator .col-md-2 button {
        text-align: center;
        float: inherit !important;
        margin: 0px auto;
        display: block;
    }
    .block-namenav {
        max-width: 100%;
    }
    b.pa-title {
        width: auto;
        margin-top: 17px;
    }
    .icon-calender {
        width: 100%;
    }
    .DayPicker {
        font-size: 1.4rem;
    }
    .bg-pend-clinic .card.card-stats {
        min-height: 164px;
    }
    .user-box .react-bs-table,
    .no-padding .react-bs-table,
    .educator-L .react-bs-table {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
    .no-padding {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .educator-task-screen .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }
    .regist-b button {
        min-width: 68px;
        margin: 0px 0px 0px 6px;
    }
    .headCl .btn-toolbar button {
        margin-bottom: 8px;
    }
    .m-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .knowledge-share .chart_flexs a {
        /* width: 80px !important; */
    }
    .Select_sec {
        margin-bottom: 5px;
        display: block !important;
    }
    .hm-icons span {
        font-size: 12px;
    }
    .bg_greysectionss button {
        margin-bottom: 10px;
    }
    .hm-icons {
        padding: 10px;
    }
    .hm-blk .hm-icons {
        width: 19.7%;
    }
    .it_service {
        width: 31.25%;
    }
    .middle-ban-adjust {
        margin-top: 0px;
    }
    .mob-touch-right {
        float: none;
    }
}

.btn-rt {
    text-align: center;
}


/**new-css(28/06/19)**/

.notification-front .front-nav ul li {
    background: #eeeeee;
    border: 1px solid #ccc;
    background: -moz-linear-gradient(top, #eeeeee 0%, #ffffff 100%);
    background: -webkit-linear-gradient(top, #eeeeee 0%, #ffffff 100%);
    background: linear-gradient(to bottom, #eeeeee 0%, #ffffff 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr='#eeeeee', endColorstr='#ffffff', GradientType=0);
    margin: 3px;
    border-radius: 3px;
}

.notification-front .front-nav ul.nav.navbar-nav li a i {
    color: #eb8931 !important;
}


/*.notification-front .front-nav ul li:last-child{
 box-shadow: none;
 background: transparent;
}*/

#tabs-with-dropdown .btn-fill.btn.btn-warning.pull-right.btn-default {
    margin-top: 28px;
}

#tabs-with-dropdown .nav-tabs>li.active>a {
    background: #ff9510;
    color: #fff;
}

#tabs-with-dropdown .nav-tabs>li>a {
    padding: 10px 28px;
    text-transform: uppercase;
    font-size: 15px;
    font-weight: 600;
    color: #2a62b1;
}

#tabs-with-dropdown .nav-tabs {
    border-bottom: none;
}

.pa-task-screen .main-content.task-screen .content textarea {
    height: 50px;
}

.pa-task-screen .main-content.task-screen .radio label::before,
.W50 .checkbox label::before,
.W50 .checkbox input[type="checkbox"]:checked+label::after,
.pa-task-screen .main-content.task-screen .radio input[type="radio"]:checked+label::after {
    color: #2a62b1 !important;
}

.pa-task-screen .main-content.task-screen .checkbox label::before {
    color: #ef7510;
}

.pa-task-screen .main-content.task-screen .checkbox input[type="checkbox"]:checked+label::after {
    color: #2a62b1;
}

.task-diet.tab-content legend {
    background: #efefef;
    padding: 3px 5px;
}

.weight-case {
    animation: blinking 1.8s infinite;
}

@keyframes blinking {
    0% {
        background-color: #fbfb34;
    }
    100% {
        background-color: #2a62b1;
        color: #fff;
    }
}

.knowledge-share .chart_flexs a {
    border-radius: 4px;
    border-right: none;
    -webkit-transition: 0.3s ease-in-out;
    -moz-transition: 0.3s ease-in-out;
    -o-transition: 0.3s ease-in-out;
    -ms-transition: 0.3s ease-in-out;
    transition: 0.3s ease-in-out;
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    position: relative;
    box-shadow: 1px 1px 8px #999;
    padding: 10px 0;
    text-align: center;
    color: #fff;
    margin: 0 5px;
    width: 180px;
}

.knowledge-share .chart_flexs a:first-child {
    background: #56c8b5;
}

.knowledge-share .chart_flexs a:nth-child(2) {
    background: #ffa509;
}

.knowledge-share .chart_flexs a:nth-child(3) {
    background: #a89fe6;
}

.knowledge-share .chart_flexs a:nth-child(4) {
    background: #d73c46;
}

.knowledge-share .chart_flexs a:nth-child(5) {
    background: #41a2c9;
}

.knowledge-share .chart_flexs a:nth-child(6) {
    background: #f96421;
}

.knowledge-share .chart_flexs a:hover {
    -webkit-transform: scale(1);
    -moz-transform: scale(1);
    -o-transform: scale(1);
    -ms-transform: scale(1);
    transform: scale(1);
    cursor: pointer;
    justify-content: center;
}

.Knowledge-Share.modal-body th {
    /* background: #ff9510;
    color: #fff; */
    /* font-size: 15px; */
    color: #000;
}

.table>thead>tr>th {
    color: #000;
}

.abc {
    margin: 0px auto;
    display: block;
}

.W50 .col-sm-12 {
    margin: 3px 0;
}

.leftscroll-bar .checkbox label,
.radio label {
    padding-left: 20px;
}

.leftscroll-bar {
    background: #ffff;
    margin-left: 0px;
    padding: 2px;
    border: 1px solid #e3e3e3;
    border-radius: 3px;
    margin-top: 15px;
    /* margin-bottom: 15px; */
    margin-bottom: 20px;
    height: 68px;
    /* overflow-y: auto; */
    font-size: 11px;
}

.leftscroll-bar .checkbox {
    margin-bottom: 0px !important;
}

.botom-icons>div {
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flexbox;
    display: flex;
    -webkit-justify-content: space-between;
    justify-content: space-between;
    -webkit-box-pack: justify;
    -moz-box-pack: justify;
    -ms-flex-pack: justify;
    padding: 0 20px;
}

.botom-icons>div .col-sm-2 {
    width: 15%;
    margin: 0;
}

.lisulahar .next-Arrow {
    margin: 20px 0;
}

.educator-task-screen .educator-header .close {
    position: absolute;
    right: 20px;
    top: 12px;
    opacity: 1;
    color: #fff;
    z-index: 1;
}

.Knowledge-Share .card {
    margin: 0;
}

.history-modal .modal-body>table>thead tr th {
    /* /* font-weight: 700 !important; */
    /* font-size: 14px !important; */
    color: #000;
}

.history-modal .modal-body>table {
    min-width: 513px;
}

.history-modal .modal-body {
    overflow-x: auto;
}

.table-responsive-new .table-condensed {
    min-width: 1100px;
}

#printable1 li.dietWidth2 {
    width: calc(50% - 17px);
    margin: 0 !important;
    padding: 0 10px !important;
}

.Knowledge-Share.modal-body .highlight.control-label {
    font-weight: bold;
    color: #000;
}

.Knowledge-Share.modal-body .insulin-input.highlight.form-control {
    color: #000;
    font-weight: bold;
}

.Knowledge-Share.modal-body .insulin-input.highlight.form-control::placeholder {
    /* modern browser */
    color: #000;
}

.consultation-CTA .mangeBtns {
    margin-bottom: 4px;
    width: 100%;
    white-space: inherit;
    margin-left: 0;
}

.py-5 {
    padding-left: 5px;
    padding-right: 5px;
}

.modal_custom .modal-header {
    margin-bottom: 0 !important;
}

.modal_custom .modal-body {
    padding: 0 !important;
    margin-bottom: 0 !important;
    min-height: calc(100vh - 48px);
}

.modal-100w.modal_custom.modal-dialog {
    margin: 0;
}

.modal_custom .modal-body .react-player video {
    max-height: 90vh;
}

.modal_custom .modal-body {
    object-fit: contain;
    overflow: hidden;
}

.pointer {
    cursor: pointer;
}

.btn-rotate {
    position: absolute;
    top: 10px;
    right: 15px;
}

.insulin-dose-modal.modal-body .highlight.control-label,
.insulin-dose-modal.modal-body .insulin-input.highlight.form-control {
    font-weight: normal;
}

.insulin-dose-modal .mange-spctop .form-group {
    margin-bottom: 0;
}

.insulin-dose-modal .mange-spctop {
    /* margin-bottom: 20px; */
}

.insulin-dose-modal .btn-simple.btn-icon {
    font-size: 0;
}

.insulin-dose-modal .form-horizontal {
    /* background-color: #f5f5f5; */
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 15px;
}

@media only screen and (max-width: 1280px) {
    b.pa-title {
        width: 100px;
    }
}

@media only screen and (max-width: 1200px) {
    .card-stats .numbers p {
        font-size: 14px;
    }
    .notification-front .front-nav .navbar-nav>li>a {
        padding: 0 8px !important;
    }
    .spc-tascreans #tabs-with-dropdown .nav-tabs>li>a {
        padding: 11px 14px;
    }
}

@media only screen and (max-width: 1024px) {
    .weight-case {
        width: auto;
    }
    .notification-front .front-nav .navbar-nav>li>a {
        padding: 0 7px !important;
    }
}

@media only screen and (max-width: 991px) {
    .table-responsive-new .react-bs-table {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
    .ditebar legend.topD {
        width: 65px;
    }
    .ditebar .dite_blue_box {
        min-width: 330px;
        padding: 0 10px;
    }
    .ditebar {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .ditebar .spc-tascreans {
        min-width: 270px;
    }
    .ditebar div {
        margin: 0;
    }
    .bg_greysectionss .Select_sec {
        display: inline-block !important;
    }
    .bg_greysectionss button {
        width: auto !important;
    }
    .knowledge-share .chart_flexs a {
        /* width: auto !important; */
    }
    .knowledge-share {
        overflow-x: auto;
        overflow-y: hidden;
    }
    .knowledge-share>.row>div {
        padding: 0 5px;
    }
    .knowledge-share .row {
        min-width: 872px;
    }
}

@media only screen and (max-width: 768px) {
    /* .knowledge-share .col-sm-2{
 width: 47%;
 margin:10px 0;
 }*/
    .task-screen .card .title {
        font-size: 14px;
    }
}

@media only screen and (max-width: 767px) {
    .flccx-header .navbar-header {
        width: 100%;
    }
    b.pa-title {
        text-align: left;
        margin-top: 20px;
    }
    .patienSearchBox button {
        margin-top: 9px;
    }
    .educator-header .react-datepicker-wrapper {
        width: 100%;
        margin-bottom: 15px;
    }
    .msd-top.leave-ttn div {
        top: -26px;
    }
    .pad15educator .col-md-2 button {
        text-align: center;
        float: inherit !important;
        margin: 0px auto;
        display: block;
    }
    .logo-h {
        width: 100px;
        padding-left: 7px;
    }
    .educator-header .header h4 {
        font-size: 15px;
    }
    .educator-task-screen .educator-header .close {
        top: 28px;
    }
    .leave-ttn {
        margin-top: 15px !important;
        margin-bottom: 15px;
    }
    .no-padding {
        padding-right: 15px !important;
        padding-left: 15px !important;
    }
    .educator-task-screen .container-fluid {
        padding-right: 0px;
        padding-left: 0px;
    }
    .list-ww {
        border: none;
    }
    .user-box .react-bs-table,
    .no-padding .react-bs-table,
    .clinic-subn .react-bs-table,
    .educator-L .react-bs-table,
    .respo-T .react-bs-table {
        width: 100%;
        margin-bottom: 15px;
        overflow-x: scroll;
        overflow-y: hidden;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        -webkit-overflow-scrolling: touch;
    }
    .spcfields .rdt {
        margin-bottom: 10px;
    }
    .m-10 {
        margin-top: 10px;
        margin-bottom: 10px;
    }
    .searchBtn {
        margin-top: 15px;
    }
    .regist-b button {
        margin: 15px 10px 0px 0px;
    }
    .sub-btn-box {
        margin-top: 15px;
    }
    .headCl .btn-toolbar button {
        margin-bottom: 10px;
    }
    .m-bb-s button {
        margin-bottom: 10px;
    }
    .m-bb-s .btn-toolbar {
        margin-left: -2px;
    }
    .ful-non {
        margin-top: 20px;
    }
    /* .front-nav .open .dropdown-menu>li>a {
        line-height: 31px!important;
        font-size: 11px!important;
        text-transform: uppercase;
        font-weight: 600;
    }*/
    .spc-card .card .content select {
        margin-bottom: 15px;
    }
    .botom-icons>div,
    .doctor-popss>div {
        -webkit-flex-wrap: wrap;
        -moz-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
    .botom-icons>div .col-sm-2,
    .doctor-popss>div .col-sm-2 {
        width: 30% !important;
        margin: 0;
        margin: 10px 0;
    }
    .knowledge-share .chart_flexs a {
        width: calc(33% - 10px);
        margin: 0 5px;
    }
    .knowledge-share .chart_flexs a {
        font-size: 10px;
    }
    .ditebar>div {
        display: inline-block;
    }
    .ditebar {
        display: flex;
        display: -webkit-flex;
        display: -moz-flex;
        display: -ms-flexbox;
    }
    .ditebar .dite_blue_box .spcmobsn {
        width: auto;
        display: inline-block !important;
    }
    .ditebar select.form-control {
        margin: 0 !important;
    }
    .bg_greysectionss button {
        width: calc(50% - 12px) !important;
        margin: 6px 6px;
    }
    .tabcont-spc .col-sm-3 {
        width: 50%;
        float: left;
        margin: 7px 0;
    }
    .knowledge-share .col-md-2.lineremove {
        margin: 0;
    }
    .Knowledge-Share table {
        min-width: 500px;
    }
    .Knowledge-Share table td {
        white-space: pre-line !important;
    }
    .btn-wd {
        min-width: 125px;
    }
    .pa-task-screen .header .title .col-sm-8,
    .pa-task-screen .header .title .col-sm-4 {
        width: 100%;
        text-align: left !important;
        padding: 0 40px 0px 15px !important;
    }
    .task-screen .card .title {
        line-height: 1.4;
    }
    .pa-task-screen .header .historybtn {
        margin: 0;
    }
    .pa-task-screen .historybtn button {
        float: left !important;
        /* margin-left: -14px; */
    }
    .pa-task-screen .historybtn div {
        width: 100% !important;
        float: left;
        max-width: 100% !important;
    }
    .Calorie table {
        min-width: 500px;
    }
    .Calorie {
        overflow-x: auto;
    }
    #printable1>table th:first-child,
    #printable1>table td:first-child {
        width: 20% !important;
    }
    #printable1 table.pt-info-box+table th {
        width: 40% !important;
    }
    #printable1 table.pt-info-box+table th:first-child {
        width: 20% !important;
    }
    div#printable1 {
        overflow-x: auto;
        width: 100% !important;
    }
    #printable1 table.table {
        min-width: 650px;
    }
}

@media only screen and (max-width: 480px) {
    .w550 {
        width: 100%;
        float: left;
        margin-bottom: 10px;
    }
    .botom-icons>div .col-sm-2,
    .doctor-popss>div .col-sm-2 {
        width: 47% !important;
    }
    .inlin-tt {
        margin-top: 10px;
    }
    .fl-rr.col-md-4 {
        float: initial;
    }
    .relativeCss .control-label .fa {
        position: initial;
        margin-left: 10px;
    }
    .relativeCss .col-sm-4 {
        margin-bottom: 13px;
    }
    .historybtn {
        margin-top: 30px;
    }
    .tabcont-spc .col-sm-3 {
        width: 100%;
    }
    .bg_greysectionss .Select_sec {
        width: 100%;
    }
    .bg_greysectionss button {
        width: calc(100% - 10px) !important;
    }
    .ditebar>div {
        display: block !important;
        width: 100% !important;
        margin: 8px 0;
    }
    .ditebar select.form-control {
        width: 100% !important;
    }
    .ditebar .dite_blue_box {
        min-width: auto !important;
        padding: 0;
    }
    .ditebar .dite_blue_box .spcmobsn {
        display: block !important;
        margin: 5px 0;
    }
    .ditebar>div.dite_btn_group {
        text-align: left;
    }
    .ditebar>div.dite_btn_group button {
        float: left;
    }
    .ditebar {
        display: block !important;
    }
    .ditebar {
        display: block !important;
    }
    .knowledge-share .row {
        min-width: auto;
        margin: 0;
    }
    .knowledge-share {
        padding: 0;
    }
    .knowledge-share>.row>div {
        width: 100% !important;
    }
    .knowledge-share .chart_flexs a {
        width: 100% !important;
        margin: 5px 0px !important;
    }
    .knowledge-share .col-md-2.lineremove {
        margin-top: 10px;
    }
    .no-print span.col-sm-7 {
        width: 100%;
        padding: 0;
    }
}

.home-ban {
    background-image: url(../img/banner_1.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    text-align: center;
}

/* .home-ban:before {
    content: "";
    position: absolute;
    background: #061e36;
    width: 100%;
    height: 100%;
    opacity: 0.5;
    left: 0;
} */

@media only screen and (max-width: 1480px) {
    .ditebar .row>div {
        margin-right: 5px;
    }
    .ditebar .row .nav-tabs a {
        padding-left: 10px !important;
        padding-right: 10px !important;
    }
    .ditebar select.form-control[name="calorie"] {
        width: 123px;
    }
    .ditebar button {
        padding-left: 5px;
        padding-right: 10px;
    }
    .dite_blue_box>div,
    .dite_btn_group>div {
        margin: 0 2px;
    }
}

.Knowledge-Share.modal-body.table-responsive .row {
    margin: 0;
    width: 50%;
    padding: 0 10px;
}

.Knowledge-Share.modal-body.table-responsive td.right-lines,
.Knowledge-Share.modal-body.table-responsive td.sapcelineheight {
    display: flex;
    display: -webkit-flex;
    display: -moz-flex;
    display: -ms-flex-box;
    -webkit-flex-wrap: wrap;
    -moz-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}

.Knowledge-Share.modal-body.table-responsive .row .col-sm-12 {
    padding: 0;
}

@media only screen and (max-width: 767px) {
    .pa-task-screen .historybtn button {
        margin-left: 0 !important;
        margin-right: 5px;
    }
    .Knowledge-Share.modal-body.table-responsive .row {
        width: 100%;
    }
    .mop-0 {
        margin-bottom: 0px !important;
    }
}

@media (max-width: 1366px) {
    .right0 {
        margin-right: 0px !important;
    }
    .spc-tascreans #tabs-with-dropdown .nav-tabs>li>a {
        font-size: 12px;
        padding: 11px 7px !important;
        font-weight: bold;
    }
}

a {
    cursor: pointer;
}

.has-error .form-control,
.form-control.error,
.has-error .form-control:focus {
    border-color: #e3e3e3 !important;
}

.form-control.error::placeholder {
    color: #fb404b;
}

.Select.required.col-3.mt-3.has-value.Select--single {
    border: 1px solid red;
    border-radius: 6px;
}

.select-width-handle select,
.select-width-handle option {
    width: 100%;
    max-width: 500px !important;
    word-break: break-all;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.clinic-list-card {
    background: #fff;
    /* min-height: 80px; */
    border: 1px solid #cccccc;
    padding: 15px;
    margin-bottom: 15px;
    display: flex;
    justify-content: space-between;
    border-radius: 4px;
    font-weight: 700;
    box-shadow: 1px 1px 10px rgb(0 0 0 / 20%);
}

.clinic-list-card b {
    text-align: right;
    min-width: 50px;
    /* display: block;
    font-size: 24px;
    font-weight: 600; */
}

.main-content .custom-state-card {
    margin-bottom: 15px !important;
}

.clinic-performance-report .custom-state-card .content {
    padding: 0;
}

.custom-state-card p {
    /* border-bottom: 1px solid #ddd; */
    font-size: 15px;
    font-weight: 500;
    padding: 8px;
    text-align: right;
    margin: 0;
}

.custom-state-card ul {
    list-style: none;
    padding-left: 0;
    margin-top: 15px;
}

.custom-state-card ul li {
    display: flex;
    justify-content: space-between;
    /* margin-bottom: 5px; */
    padding: 5px 8px;
    border-bottom: 1px solid #eee;
}

.custom-state-card ul li:last-child {
    /* border: none; */
}

.custom-state-card ul li:hover {
    background-color: #f5f5f5;
}

.custom-state-card ul li span.card-lable {
    font-size: 14px;
    font-weight: 700;
}

.custom-state-card ul li span.lable-value {
    font-size: 14px;
    font-weight: 700;
    /* width: 30px;
    height: 30px;
    background: #fff; */
    display: flex;
    justify-content: center;
    align-items: flex-start;
    border-radius: 100%;
    min-width: 40px;
    color: #1174d7;
    /* box-shadow: 1px 1px 10px rgb(0 0 0 / 30%); */
}